.ap-page-wrapper {
    padding: 16px;
}

/* --------------------------- USERS --------------------------- */
.ap-unknown-users-header {
    margin-top: 16px;
}
.ap-users-list-wrapper {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-x: auto;
}
.ap-users-graphs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
    margin-bottom: 56px;
    gap: 40px;
}
.ap-users-graph-title {
    margin-bottom: 8px;
}
.ap-users-graph {
    width: 380px;
    height: 280px;
}
.ap-users-list-header {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}
.ap-users-list-header-text {
    font-weight: 700;
    opacity: 0.5;
    font-size: 12px;
}
.ap-user-pic-name-header {
    width: 144px;
    flex-shrink: 0;
}   
.ap-user-row {
    display: flex;
    flex-direction: row;
    width: max-content;
    gap: 8px;
    padding-top: 12px;
    padding-bottom: 4px;
    border-top: 1px solid rgba(255,255,255,0.15);
    /* align-items: center; */
}
.ap-user-pic-name {
    display: flex;
    flex-direction: row;
    gap: 6px;
    /* align-items: center; */
}
.ap-user-pic {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
.ap-user-name {
    width: 120px;
    flex-shrink: 0;
}
.ap-user-timestamp {
    width: 110px;
    flex-shrink: 0;
}
.ap-user-favs {
    width: 48px;
    text-align: center;
    flex-shrink: 0;
}
.ap-user-groups {
    width: 48px;
    text-align: center;
    flex-shrink: 0;
}
.ap-user-group-details {
    width: 300px;
    flex-shrink: 0;
    color: white;
    margin-right: 24px;
}
.ap-user-schedules {
    width: 200px;
    flex-shrink: 0;
    font-size: 12px;
}
.ap-user-recents {
    width: 48px;
    text-align: center;
    flex-shrink: 0;
    margin-right: 16px;
}
.ap-user-schedule-details {
    display: flex;
    flex-direction: row;
    gap: 4px;
}
.ap-ugd-wrapper {
    font-size: 12px;
}
.ap-ugd-flex-sb {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.ap-ugd-flex {
    display: flex;
    flex-direction: row;
    gap: 4px;
}
.ap-ugd-groupname {
    font-weight: 700;
    cursor: pointer;
}
.ap-ugd-membercount {
    opacity: 0.75;
}
.ap-ugd-festival {
    opacity: 0.5;
}
.ap-ugd-members {
    opacity: 0.75;
}
.ap-user-email {
    width: 248px;
    flex-shrink: 0;
}
.ap-user-id {
    width: 240px;
    flex-shrink: 0;
}
.ap-empty {
    opacity: 0.1;
}

/* --------------------------- FESTIVALS --------------------------- */
.ap-festivals-list-wrapper {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-x: auto;
}
.ap-festivals-list-header {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}
.ap-festivals-list-header-text {
    font-weight: 700;
    opacity: 0.5;
}
.ap-festival-row {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}
.ap-festival-name {
    width: 120px;
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ap-festival-schedules {
    width: 64px;
    flex-shrink: 0;
    text-align: center;
}
.ap-festival-nonzero {
    width: 64px;
    flex-shrink: 0;
    text-align: center;
}
.ap-festival-groups {
    width: 64px;
    flex-shrink: 0;
    text-align: center;
}
.ap-festival-favs {
    width: 64px;
    flex-shrink: 0;
    text-align: center;
}

/* --------------------------- NEW BLAST --------------------------- */
.ap-new-blast-wrapper {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.ap-new-blast-header {
    font-size: 24px;
    margin-bottom: 20px;
}

.ap-new-blast-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.ap-new-blast-form textarea {
    min-height: 200px;
    resize: vertical;
    font-family: inherit;
}

.ap-blast-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.ap-blast-tag {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 6px 12px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
}

.ap-blast-tag:hover {
    background: rgba(255, 255, 255, 0.15);
}

.ap-blast-tag input {
    margin: 0;
}

