.edit-group-picture-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.edit-group-dropzone {
    border: 2px solid rgba(255,255,255,0.2);
    width: 160px;
    height: 160px;
    border-radius: var(--border-radius-1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 0 40px 0 rgba(255,255,255,0.1);
    transition: var(--transition-1);
}
.edit-group-picture-container {
    width: 160px;
    height: 160px;
    border-radius: var(--border-radius-1);
    overflow: hidden;
}
.edit-group-img {
    width: 160px;
    height: 160px;
    border-radius: 6px;
}