.group-main-header {
    padding: 16px;
    height: 130px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    width: calc(100% - 32px);
}
.group-info-wrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex: 1;
}
.group-details-text {
    flex: 1;
}
.extra-padding-2px {
    padding-top: 2px;
}
.img-group {
    width: 102px;
    height: 102px;
    border-radius: var(--border-radius-2);
    border: 1px solid rgba(255,255,255,0.3);
}
.group-name-wrapper {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}
.group-name {
    font-size: 28px;
    line-height: 32px;
    font-weight: 800;
}
.group-num-members-wrapper {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    margin-top: 9px;
    opacity: 1;
    transition: var(--transition-1);
}
.group-actions-wrapper {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}

/* JOIN GROUP */
.join-group-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 216px;
}
.join-group-header {
    padding: 18px 16px 16px 16px;
}
.join-group-header-text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
}
.join-group-header-text {
    font-size: 24px;
    line-height: 32px;
    color: white;
    font-weight: 900;
}
.join-group-name {
    margin-top: 16px;
}
.join-group-subtext {
    opacity: 0.8;
}
.img-group-join {
    width: 216px;
    height: 216px;
    border-radius: var(--border-radius-2);
    border: 1px solid rgba(255,255,255,0.3);
    box-shadow: 0 0 40px 0 rgba(255,255,255,0.25);
}
.join-group-members-wrapper {
    margin-top: 4px;
}
.btn-join-group {
    margin-top: 24px;
}

@media only screen and (max-width: 1000px) {
    .group-main-header {
        height: 177px;
        background: var(--color-bg-2);
        border-radius: 0;
        width: calc(100% - 32px);
    }
    .group-info-wrapper {
        margin-top: 12px;
    }
    .group-name-wrapper {
        margin-top: 0;
    }
    .img-group {
        width: 112px;
        height: 112px;
        border-radius: var(--border-radius-2);
        border: 1px solid rgba(255,255,255,0.3);
    }
    .group-num-members-wrapper {
        flex-direction: column;
        gap: 12px;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 9px;
        opacity: 1;
        transition: var(--transition-1);
    }
    .btn-group-share {
        margin-left: 0;
    }
}