.btn-grp-wrapper {
    display: flex;
    flex-direction: row;
    border-radius: var(--border-radius-2);
    outline: 0;
    transition: var(--transition-1);
    overflow: hidden;
    gap: 2px;
    user-select: none;
    -webkit-tap-highlight-color: transparent; /* Remove tap highlight on mobile */
    user-select: none; /* Prevent text selection */
}
.btn-grp-wrapper:hover {
    border-color: rgba(255,255,255,0.75);
}
.btn-grp-wrapper:focus {
    border-color: rgba(255,255,255,1);
}

.btn-grp-btn {
    display: flex;
    justify-content: center;
    padding: 5px 12px;
    transition: var(--transition-1);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    background: rgb(37, 43, 77);
    cursor: pointer;
    -webkit-tap-highlight-color: transparent; /* Remove tap highlight on mobile */
    user-select: none; /* Prevent text selection */
}
.btn-grp-btn:focus,
.btn-grp-btn:focus-visible,
.btn-grp-btn:focus-within,
.btn-grp-btn:active {
    outline: none;
    box-shadow: none;
    background: rgb(20,20,20);
    opacity: 1;
}

.btn-grp-btn:hover {
    background: rgb(51, 60, 107);
}

.btn-grp-btn:active {
    background: rgba(255,255,255,0.05);
}

.btn-grp-btn.active {
    opacity: 1;
    background: var(--color-blue-1);
    color: white;
    font-weight: 800;
}

@media only screen and (max-width: 1000px) {
    .btn-grp-btn {
        background: rgb(28, 31, 47);
    }
}