/* -------------------- BLOCKSCHEDULE BLOCK -------------------- */
/* Vertical flip */
.block2 {
    position: relative;
    letter-spacing: -0.25px;
    text-transform: uppercase;
    padding: 3px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.25s all ease;
    user-select: none;
    perspective: 1000px;
    transform: rotateX(360deg);
    word-wrap: break-word;
}
.block2:hover {
    background: rgba(255,255,255,0.2);
}

.block2.clicked-once {
    transform: rotateX(0deg);
}
.block2.clicked-twice {
    transform: rotateX(-360deg);
}
.block2-artist-twice {
    font-weight: 700;
}
.block2-artist-time {
    opacity: 0.75;
    margin-top: 1px;
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
}
.block2-artist-end-time {
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
    transition: var(--transition-1);
}
.artist-time-visible {
    opacity: 1;
}
.artist-time-hidden {
    opacity: 0;
}

/* -------------------- SETTIMESCHEDULE BLOCK -------------------- */
/* Horizontal flip */
.block2-set {
    position: relative;
    font-size: 11px;
    letter-spacing: -0.25px;
    text-transform: uppercase;
    padding: 3px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s all ease;
    user-select: none;
    perspective: 1000px;
    transform: rotateY(360deg);
    word-wrap: break-word;
}
.block2-set:hover {
    background: rgba(255,255,255,0.2);
}
.block2-set.clicked-once {
    transform: rotateY(0deg);
}
.block2-set.clicked-twice {
    transform: rotateY(-360deg);
}

/* -------------------- <3 HEART ICON -------------------- */
@keyframes slideIn {
    0% {
        transform: translateY(-80px) scale(0);
        opacity: 0;
    }
    100% {
        transform: translateY(0) scale(0.5);
        opacity: 1;
    }
}
@keyframes heartFadeIn {
    0% {
        transform: translateY(-80px) scale(0);
        opacity: 0;
    }
    100% {
        transform: translateY(0) scale(1);
        opacity: 1;
    }
}
@keyframes pulse {
    0% {
        transform: scale(0.75);
    }
    20% {
        transform: scale(1.1) translateY(-1px);
    }
    100% {
        transform: scale(1);
    }
}
.heart-icon {
    position: absolute;
    bottom: 3px;
    right: 3px;
    opacity: 0;
    animation: none;
    transition: var(--transition-1);
}
.heart-icon.visible {
    opacity: 1;
    animation: slideIn 0.27s ease-in-out forwards, pulse 0.3s ease-in 0.3s forwards;
}

.avatar-set-container {
    position: absolute;
    bottom: 3px;
    right: 3px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

@media only screen and (max-width: 1000px) {
    .heart-icon.visible {
        opacity: 1;
        animation: heartFadeIn 0.27s ease;
    }
}