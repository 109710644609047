/* --------------------- VARIABLES --------------------- */
:root {
  --page-margin: 12px;
  --nav-width: 304px;
  --topnav-lr-padding: 24px;
  --color-bg-1: #090A11;
  --color-bg-2: #141725;
  --color-bg-3: #272D4C;
  --color-bg-4: #212535;
  --color-blue-1: #0085FF;
  --color-red-1: #c30d0d;
  --color-festival-favorite: #FF007A;
  --border-radius-1: 8px;
  --border-radius-2: 4px;
  --color-border-1: rgba(0,0,0,0.3);
  --color-border-2: rgba(230,230,230,1);
  --color-text-1: white;
  --transition-1: 0.25s all ease;
  --color-theme-1: rgb(31, 48, 80);
  --m-nav-height: 48px;
}

/* ---------------------- GENERAL ---------------------- */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg-1);
}
body {
  font-family: 'Lato', sans-serif;
  color: var(--color-text-1);
  overflow: hidden;
}
.App {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  opacity: 0;
}
/* .main-content {
  flex: 1;
  height: 100%;
  margin-left: calc(var(--nav-width) + 2 * var(--page-margin));
  overflow: hidden;
} */
.main-wrapper {
  margin: 12px 12px 12px 0;
  background-color: var(--color-bg-2);
  border-radius: var(--border-radius-1);
  height: calc(100vh - (2 * var(--page-margin)));
  width: calc(100% - var(--page-margin));
  margin-left: calc(var(--nav-width) + 2 * (var(--page-margin)));
  box-sizing: border-box;
  overflow: auto;
  overflow-x: hidden;
  background: linear-gradient(#141725 50%, #0C0E19);
  position: relative;
}
.main-wrapper-set {
  margin: 12px 12px 12px 0;
  background-color: var(--color-bg-2);
  border-radius: var(--border-radius-1);
  height: calc(100vh - (2 * var(--page-margin)));
  width: calc(100% - var(--page-margin));
  margin-left: calc(var(--nav-width) + 2 * (var(--page-margin)));
  box-sizing: border-box;
  overflow: auto;
  overflow-x: hidden;
  background: linear-gradient(#141725 50%, #0C0E19);
  position: relative;
}
.opacity50 {
  opacity: 0.5;
}
.opacity60 {
  opacity: 0.6;
}
.opacity80 {
  opacity: 0.8;
}
.text-gradient {
  background: linear-gradient(90deg, #FFA471, #FF52AB 50%, #00A3FF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

/* ---------------------- TYPE STYLES ---------------------- */
h1 {
  margin: 0;
  padding: 0;
  font-size: 32px;
  line-height: 40px;
  font-weight: 800;
  letter-spacing: 0.25px;
}
h2 {
  margin: 0;
  padding: 0;
  font-size: 20px;
}
h3 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}
h4 {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 900;
}
h5 {
  margin: 0;
  padding: 0;
  font-size: 10px;
  line-height: 14px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
}
p {
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  margin: 0;
}
.body-s {
  font-size: 12px;
  line-height: 16px;
}
.bold {
  font-weight: 700;
}

.page-context-bar {
  margin-bottom: 8px;
}

img {
  user-select: none;
}

.icon-link {
  color: white;
  cursor: pointer;
  transition: var(--transition-1);
  opacity: 1;
  border: none;
  outline: none;
}
.icon-link:hover {
  opacity: 0.7;
}
.icon-link:active {
  opacity: 0.5;
}

.no-scroll {
  overflow: hidden !important;
}

.btn-back-festivals {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.report-issue-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* ---------------------- PRIMARY BUTTON ---------------------- */
.btn-primary {
  outline: none;
  border: none;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  padding: 8px;
  background-color: var(--color-blue-1);
  color: white;
  border-radius: var(--border-radius-2);
  cursor: pointer;
  width: 100%;
  transition: var(--transition-1);
  outline: 0px solid var(--color-blue-1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  user-select: none;
}
.btn-primary:hover {
  opacity: 0.7;
}
.btn-primary:active {
  opacity: 0.5;
}
.btn-primary:focus {
  outline: 1px solid var(--color-blue-1);
  outline-offset: 2px;
}
.btn-primary:disabled {
  opacity: 0.5;
  cursor: default;
}
.btn-primary.danger-btn {
  background-color: var(--color-red-1);
}
.btn-primary.danger-btn:focus {
  outline: 1px solid var(--color-red-1);
  outline-offset: 2px;
}
.btn-fixed-width {
  width: 120px;
}

.btn-primary-sm {
  outline: none;
  border: none;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  padding: 5px 12px 5px 9px;
  background-color: var(--color-blue-1);
  color: white;
  border-radius: var(--border-radius-2);
  cursor: pointer;
  transition: var(--transition-1);
  outline: 0px solid var(--color-blue-1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  user-select: none;
}
.btn-share-link {
  margin-left: 8px;
}
.btn-primary-sm:hover {
  opacity: 0.7;
}
.btn-primary-sm:active {
  opacity: 0.5;
}
.btn-primary-sm:disabled {
  opacity: 0.5;
  cursor: default;
}

.btn-with-icon {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  background: transparent;
  background-color: transparent;
  color: white;
  outline: none;
  border: none;
  opacity: 0.6;
  transition: var(--transition-1);
  cursor: pointer;
  user-select: none;
}
.btn-with-icon:hover {
  opacity: 1;
  color: var(--color-blue-1);
}
.btn-with-icon:active {
  opacity: 0.5;
}
.btn-with-icon.danger-btn:hover {
  opacity: 1;
  color: var(--color-red-1);
}
.btn-with-icon.danger-btn:active {
  opacity: 0.5;
}
.btn-with-icon:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}
.btn-with-icon:disabled:hover {
  opacity: 0.2;
  color: white;
}

/* ---------------------- TEXT BUTTON ---------------------- */
.btn-text-blue {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: var(--color-blue-1);
  cursor: pointer;
  transition: var(--transition-1);
}
.btn-text-blue:hover {
  opacity: 0.75;
}
.btn-text-blue:active {
  opacity: 0.5;
}
.btn-text-blue.sm {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 600;
}

.btn-text-link {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--color-blue-1);
  cursor: pointer;
  transition: var(--transition-1);
  /* text-decoration: underline; */
}
.btn-text-link:hover {
  opacity: 0.75;
}
.btn-text-link:active {
  opacity: 0.5;
}

/* ---------------------- TEXT INPUT ---------------------- */
.input-default {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 12px;
  width: 288px;
  border: 1px solid rgba(255,255,255,0.2);
  border-radius: 4px;
  background: transparent;
  color: white;
  outline: none;
  transition: var(--transition-1);
  box-sizing: border-box;
  width: 100%;
}
.input-default::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.input-default:hover {
  border: 1px solid rgba(255,255,255,0.4);
}   
.input-default:focus {
  border: 1px solid rgba(0,133,255,1);
}
.input-error {
  border-color: red;
}
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}
.input-error-wrapper {
  display: flex;
  flex-direction: column;
}
.textarea-default {
  height: 80px;
  resize: none;
}
.input-label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}
.input-label-reg {
  font-weight: 500;
  opacity: 0.6;
  margin-left: 4px;
}
.label-subtext {
  opacity: 0.8;
  margin-top: -6px;
  padding-top: 0;
}
.input-select {
  padding-left: 12px;
  padding-right: 20px;
}
.input-required {
  color: #FF0000;
  font-size: 15px;
}
select.input-default {
  background: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 5 5"><path fill="none" stroke="%23A9A9A9" d="M0 0l2 2 2-2"/></svg>') no-repeat;
  background-position: right 12px center;
  background-position-y: 18px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* ----------------------- SLIDER ----------------------- */
input[type="range"] {
  -webkit-appearance: none;
  appearance: none; 
  /* width: 100%; */
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 3px;
  background: rgba(255,255,255,0.3);
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none; 
  height: 12px;
  width: 12px;
  background-color: var(--color-blue-1);
  border-radius: 50%;
  border: none;
  transition: .2s ease-in-out;
}
input[type="range"]::-moz-range-thumb {
  height: 12px;
  width: 12px;
  background-color: var(--color-blue-1);
  border-radius: 50%;
  border: none;
  transition: .2s ease-in-out;
}
input[type="range"]::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 8px rgba(0,133,255, .1)
}
input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0 0 0 8px rgba(0,133,255, .2)
}
input[type="range"]:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 8px rgba(0,133,255, .2)
}
input[type="range"]::-moz-range-thumb:hover {
  box-shadow: 0 0 0 8px rgba(0,133,255, .1)
}
input[type="range"]:active::-moz-range-thumb {
  box-shadow: 0 0 0 8px rgba(0,133,255, .2)
}
input[type="range"]:focus::-moz-range-thumb {
  box-shadow: 0 0 0 8px rgba(0,133,255, .2)    
}

/* ----------------------- TOGGLE ----------------------- */
.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
}
.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .3s;
  transition: .3s;
}
input:checked + .slider {
  background-color: var(--color-blue-1)
}
input:focus + .slider {
  box-shadow: 0 0 1px var(--color-blue-1)
}
input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}
.slider.round {
  border-radius: 20px;
}
.slider.round:before {
  border-radius: 50%;
}
input:disabled + .slider {
  cursor: not-allowed;
}

@media only screen and (max-width: 1000px) {
  html, body, #root {
    margin: 0;
    padding: 0;
    height: auto;
    min-height: 100%;
    overflow-y: auto;
    overscroll-behavior-y: none;
    display: flex;
    flex-direction: column;
    background-color: var(--color-bg-1);
    /* -webkit-overflow-scrolling: touch; */
  }
  .App {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: unset;
  }
  .nav-wrapper {
    display: none;
  }
  .main-wrapper {
    margin: 0;
    background-color: var(--color-bg-1);
    background: linear-gradient(#0c0e18 50%, #0C0E19);
    width: 100%;
    height: auto;
    overflow-y: unset;
    /* overscroll-behavior-y: contain; */
  }
  .main-wrapper-set {
    margin: 0;
    border-radius: 0;
    background-color: var(--color-bg-1);
    background: linear-gradient(#0c0e18 50%, #0C0E19);
    width: 100%;
    height: 100%;
    overflow: unset;
    overflow-x: hidden;
    overscroll-behavior-y: none;
  }
  .main-content {
      margin-left: 0 !important;
      overflow: unset;
  }
  h1 {
    font-size: 26px;
    line-height: 36px;
  }
  .input-default {
    font-size: 16px;
  }
  .report-issue-wrapper {
    width: 100%;
    padding-bottom: 4px;
  }
}

/* -------------------------- LATO FONT -------------------------- */
/* Webfont: Lato-Hairline */@font-face {
  font-family: 'Lato';
  src: url('/public/fonts/Lato-Hairline.woff2') format('woff2'), /* Modern Browsers */
       url('/public/fonts/Lato-Hairline.woff') format('woff'), /* Modern Browsers */
       url('/public/fonts/Lato-Hairline.ttf') format('truetype');
  font-style: normal;
  font-weight: 100;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-HairlineItalic */@font-face {
  font-family: 'Lato';
  src: url('/public/fonts/Lato-HairlineItalic.woff2') format('woff2'), /* Modern Browsers */
       url('/public/fonts/Lato-HairlineItalic.woff') format('woff'), /* Modern Browsers */
       url('/public/fonts/Lato-HairlineItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 100;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-Thin */@font-face {
  font-family: 'Lato';
  src: url('/public/fonts/Lato-Thin.woff2') format('woff2'), /* Modern Browsers */
       url('/public/fonts/Lato-Thin.woff') format('woff'), /* Modern Browsers */
       url('/public/fonts/Lato-Thin.ttf') format('truetype');
  font-style: normal;
  font-weight: 200;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-ThinItalic */@font-face {
  font-family: 'Lato';
  src: url('/public/fonts/Lato-ThinItalic.woff2') format('woff2'), /* Modern Browsers */
       url('/public/fonts/Lato-ThinItalic.woff') format('woff'), /* Modern Browsers */
       url('/public/fonts/Lato-ThinItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 200;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-Light */@font-face {
  font-family: 'Lato';
  src: url('/public/fonts/Lato-Light.woff2') format('woff2'), /* Modern Browsers */
       url('/public/fonts/Lato-Light.woff') format('woff'), /* Modern Browsers */
       url('/public/fonts/Lato-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-LightItalic */@font-face {
  font-family: 'Lato';
  src: url('/public/fonts/Lato-LightItalic.woff2') format('woff2'), /* Modern Browsers */
       url('/public/fonts/Lato-LightItalic.woff') format('woff'), /* Modern Browsers */
       url('/public/fonts/Lato-LightItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-Regular */@font-face {
  font-family: 'Lato';
  src: url('/public/fonts/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */
       url('/public/fonts/Lato-Regular.woff') format('woff'), /* Modern Browsers */
       url('/public/fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-Italic */@font-face {
  font-family: 'Lato';
  src: url('/public/fonts/Lato-Italic.woff2') format('woff2'), /* Modern Browsers */
       url('/public/fonts/Lato-Italic.woff') format('woff'), /* Modern Browsers */
       url('/public/fonts/Lato-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-Medium */@font-face {
  font-family: 'Lato';
  src: url('/public/fonts/Lato-Medium.woff2') format('woff2'), /* Modern Browsers */
       url('/public/fonts/Lato-Medium.woff') format('woff'), /* Modern Browsers */
       url('/public/fonts/Lato-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-MediumItalic */@font-face {
  font-family: 'Lato';
  src: url('/public/fonts/Lato-MediumItalic.woff2') format('woff2'), /* Modern Browsers */
       url('/public/fonts/Lato-MediumItalic.woff') format('woff'), /* Modern Browsers */
       url('/public/fonts/Lato-MediumItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-Semibold */@font-face {
  font-family: 'Lato';
  src: url('/public/fonts/Lato-Semibold.woff2') format('woff2'), /* Modern Browsers */
       url('/public/fonts/Lato-Semibold.woff') format('woff'), /* Modern Browsers */
       url('/public/fonts/Lato-Semibold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-SemiboldItalic */@font-face {
  font-family: 'Lato';
  src: url('/public/fonts/Lato-SemiboldItalic.woff2') format('woff2'), /* Modern Browsers */
       url('/public/fonts/Lato-SemiboldItalic.woff') format('woff'), /* Modern Browsers */
       url('/public/fonts/Lato-SemiboldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-Bold */@font-face {
  font-family: 'Lato';
  src: url('/public/fonts/Lato-Bold.woff2') format('woff2'), /* Modern Browsers */
       url('/public/fonts/Lato-Bold.woff') format('woff'), /* Modern Browsers */
       url('/public/fonts/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-BoldItalic */@font-face {
  font-family: 'Lato';
  src: url('/public/fonts/Lato-BoldItalic.woff2') format('woff2'), /* Modern Browsers */
       url('/public/fonts/Lato-BoldItalic.woff') format('woff'), /* Modern Browsers */
       url('/public/fonts/Lato-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-Heavy */@font-face {
  font-family: 'Lato';
  src: url('/public/fonts/Lato-Heavy.woff2') format('woff2'), /* Modern Browsers */
       url('/public/fonts/Lato-Heavy.woff') format('woff'), /* Modern Browsers */
       url('/public/fonts/Lato-Heavy.ttf') format('truetype');
  font-style: normal;
  font-weight: 800;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-HeavyItalic */@font-face {
  font-family: 'Lato';
  src: url('/public/fonts/Lato-HeavyItalic.woff2') format('woff2'), /* Modern Browsers */
       url('/public/fonts/Lato-HeavyItalic.woff') format('woff'), /* Modern Browsers */
       url('/public/fonts/Lato-HeavyItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 800;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-Black */@font-face {
  font-family: 'Lato';
  src: url('/public/fonts/Lato-Black.woff2') format('woff2'), /* Modern Browsers */
       url('/public/fonts/Lato-Black.woff') format('woff'), /* Modern Browsers */
       url('/public/fonts/Lato-Black.ttf') format('truetype');
  font-style: normal;
  font-weight: 900;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-BlackItalic */@font-face {
  font-family: 'Lato';
  src: url('/public/fonts/Lato-BlackItalic.woff2') format('woff2'), /* Modern Browsers */
       url('/public/fonts/Lato-BlackItalic.woff') format('woff'), /* Modern Browsers */
       url('/public/fonts/Lato-BlackItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 900;
  text-rendering: optimizeLegibility;
}
