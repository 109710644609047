@keyframes modalFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes modalSlideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes modalSlideOut {
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(-20px);
        opacity: 0;
    }
}
@keyframes modalFadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.modal-overlay {
    background-color: rgba(0, 0, 0, 0.8) !important;
    animation: modalFadeIn 0.3s ease-in-out !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    z-index: 1000 !important;
}
.modal-wrapper {
    outline: none;
    border: none;
    display: flex !important;
    flex-direction: column !important;
    max-width: fit-content !important;
    background-color: #181D2C !important;
    color: #FFFFFF !important;
    border-radius: 8px !important;
    animation: modalSlideIn 0.3s ease-in-out !important;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.8) !important;
    transition: var(--transition-1) !important;
}
.modal-wrapper-close {
    animation: modalSlideOut 0.3s ease-in-out forwards !important;
}
.modal-overlay-close {
    animation: modalFadeOut 0.3s ease-in-out forwards !important;
}
.modal-content {
    margin-top: 16px;
}

/* ----------------- HEATMAP TYPES MODAL ----------------- */
.modal-heatmap-types {
    width: 440px;
    display: flex;
    flex-direction: column;
    transition: var(--transition-1);
    padding: 24px;
}
.intro-wrapper {
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
}
.intro-text {
    opacity: 0.8;
    line-height: 24px;
}
.heatmap-sample-wrapper {
    margin-top: 12px;
}
.heatmap-sample {
    border-radius: var(--border-radius-2);
    height: 8px;
    width: 100%;
    background: linear-gradient(90deg, var(--color-bg-1) 0%, #00BE1E 100%);
}
.heatmap-sample-labels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
}
.heatmap-sample-label {
    opacity: 0.5;
    font-size: 12px;
    line-height: 12px;
}
.heatmap-type-wrapper {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-top: 16px;
}
.heatmap-type-title {
    width: 80px;
    font-weight: 800;
    line-height: 24px;
    opacity: 0.95;
}
.heatmap-type-description-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1;
}
.heatmap-type-description-tagline {
    opacity: 0.95;
    flex: 1;
    line-height: 24px;
    font-weight: 600;
}
.heatmap-type-description {
    opacity: 0.8;
    flex: 1;
    line-height: 24px;
}

/* ------------------- REQUEST MODAL ------------------- */
.modal-request {
    width: 440px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    transition: var(--transition-1);
}
.form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
}
.checkbox-offset {
    margin-top: 12px;
    margin-left: 4px;
}
.checkbox-offset-2 {
    margin-top: 2px;
    margin-left: 4px;
}

/* ------------------- IMAGE MODAL ------------------- */
.modal-image {
    width: min-content;
    padding: 24px;
    display: flex;
    flex-direction: column;
    transition: var(--transition-1);
}
.export-modal-content {
    margin-top: 12px;
    overflow: auto;
}
.export-modal-content-no-padding {
    margin-top: 12px;
    overflow: auto;
}
.x-select-stage {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;
    width: 320px;
}
.x-user-inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
}
.export-form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 24px;
}
.export-dims-inputs-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}
.export-colors-wrapper {
    margin-top: 16px;
}
.x-display-options-wrapper {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
}
.x-seed-phrase-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0;
}
.x-user-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.icon-dims-separator {
    opacity: 0.5;
}
.export-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.export-actions {
    padding-top: 24px;
}
.export-loading-wrapper {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 320px;
}
.export-imgs-text-wrapper {
    padding: 0;
    padding-bottom: 16px;
}
.export-all-imgs-wrapper {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 100%;
    padding: 4px 4px;
    overflow-x: scroll;
}
.export-img-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.export-day-header {
    font-size: 11px;
    margin-bottom: 12px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
}
.export-img {
    height: 360px;
    width: auto;
    border-radius: var(--border-radius-1);
}

/* ------------------ CONFIRMATION MODAL ------------------ */
.modal-confirmation {
    width: 360px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    transition: var(--transition-1);
}
.modal-btn-group {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: flex-end;
}

/* ------------------ EDIT GROUP MODAL ------------------ */
.modal-edit-group {
    width: 320px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    transition: var(--transition-1);
}

@media only screen and (max-width: 1000px) {
    .modal-overlay {
        z-index: 1000 !important;
    }
    .modal-wrapper {
        margin: 16px;
        max-height: calc(100% - 60px) !important;
        z-index: 1000 !important;
    }
    .modal-request {
        width: calc(100% - 32px);
        height: 100%;
        overflow: auto;
        padding: 16px;
    }
    .modal-heatmap-types {
        width: calc(100% - 32px);
        height: 100%;
        overflow: auto;
        padding: 16px;
    }
    .modal-image {
        width: calc(100vw - 32px);
        height: 100%;
        overflow: hidden;
        padding: 0;
    }
    .export-loading-wrapper {
        width: 100%;
        padding: 24px 0;
    }
    .export-modal-content {
        margin-top: 0;
        width: calc(100% - 32px);
        padding: 0 16px;
        padding-bottom: 16px;
    }
    .export-modal-content-no-padding {
        margin-top: 0;
        width: 100%;
        /* padding-bottom: 16px; */
        overflow-x: hidden;
    }
    .export-imgs-text-wrapper {
        padding: 16px;
    }
    .export-all-imgs-wrapper {
        padding: 0 16px;
        padding-bottom: 16px;
        overflow-x: auto;
        gap: 0;
        box-sizing: border-box;
    }
    .export-img-wrapper {
        margin-right: 16px;
        /* padding-right: 16px; */
    }
    .export-day-header {
        margin-bottom: 8px;
    }
    .x-select-stage {
        width: 100%;
    }
    .modal-confirmation {
        width: calc(100% - 48px);
        height: 100%;
        overflow: auto;
        padding: 24px;
    }
    .export-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 28px);
        border-bottom: 1px solid rgba(255,255,255,0.1);
        padding: 16px;
    }
    .export-actions {
        padding: 16px;
        border-top: 1px solid rgba(255,255,255,0.1);
    }
}