.lineup-tag {
    font-size: 10px;
    line-height: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    padding: 3px 6px;
    border: 1px solid transparent;
    border-radius: 2px;
    display: inline-block;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}

/* Status True */
.lineup-tag.true {
    background-color: rgba(47, 235, 0, 0.15); /* #2FEB00 fill at 15% opacity */
    border-color: #2FEB00;
}

/* Status False */
.lineup-tag.false {
    background-color: rgba(126, 126, 126, 0.15); /* #7E7E7E fill at 15% opacity */
    border-color: #7E7E7E;
}

/* Status True and Text is "Set times" */
.lineup-tag.complete-1 {
    background: linear-gradient(90deg, rgba(255, 164, 113, 0.24), rgba(255, 121, 144, 0.3));
    border: 1px solid transparent;
    position: relative;
}
.lineup-tag.complete-1::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 2px;
    padding: 1px;
    background: linear-gradient(90deg, rgba(255, 164, 113, 1), rgba(255, 121, 144, 1));
    mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.lineup-tag.complete-2 {
    background: linear-gradient(90deg, rgba(255, 115, 148, 0.35), rgba(211, 97, 186, 0.35));
    border: 1px solid transparent;
    position: relative;
}
.lineup-tag.complete-2::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 2px;
    padding: 1px;
    background: linear-gradient(90deg, rgba(255, 115, 148, 1), rgba(211, 97, 186, 1));
    mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.lineup-tag.complete-3 {
    background: linear-gradient(90deg, rgba(193, 102, 192, 0.4), rgba(0, 163, 255, 0.4));
    border: 1px solid transparent;
    position: relative;
}
.lineup-tag.complete-3::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 2px;
    padding: 1px;
    background: linear-gradient(90deg, rgba(193, 102, 192, 1), rgba(0, 163, 255, 1));
    mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

/* .lineup-tag.complete-1b {
    background: linear-gradient(90deg, rgba(255, 125, 141, 0.24), rgba(255, 98, 160, 0.3));
    border: 1px solid transparent;
    position: relative;
}
.lineup-tag.complete-1b::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 2px;
    padding: 1px;
    background: linear-gradient(90deg, rgba(255, 125, 141, 0.7), rgba(255, 98, 160, .7));
    mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.lineup-tag.complete-2b {
    background: linear-gradient(90deg, rgba(255, 95, 163, 0.35), rgba(211, 97, 186, 0.35));
    border: 1px solid transparent;
    position: relative;
}
.lineup-tag.complete-2b::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 2px;
    padding: 1px;
    background: linear-gradient(90deg, rgba(255, 95, 163, 0.7), rgba(211, 97, 186, 0.85));
    mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.lineup-tag.complete-3b {
    background: linear-gradient(90deg, rgba(201, 100, 189, 0.4), rgba(91, 135, 225, 0.4));
    border: 1px solid transparent;
    position: relative;
}
.lineup-tag.complete-3b::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 2px;
    padding: 1px;
    background: linear-gradient(90deg, rgba(201, 100, 189, 1), rgba(91, 135, 225, 1));
    mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
} */