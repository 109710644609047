@keyframes fadeInSite {
    from {
        opacity: 0;
        transform: translateY(4px) scale(0.995);
    }
    to {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}
@keyframes fadeInSiteNoScale {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes fadeOutContent {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
  
.App.fade-in-site {
    animation: fadeInSite 0.7s ease-in-out forwards;
    opacity: 1;
}

.loading-full {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin-top: 32px;
    gap: 16px;
}
.loading-full-percent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    gap: 16px;
}
.logo-circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: linear-gradient(135deg, #FFA471, #FFA471 14.64%, #FF52AB 50%, #00A3FF 85.36%, #00A3FF);
    animation: spin 2s linear infinite;
}
.loading-text {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 10px;
    opacity: 0.3;
}
.fade-in-content {
    /* opacity: 0; */
    animation: fadeInSite 0.5s ease-in-out forwards;
}
.fade-in-nav {
    /* opacity: 0; */
    animation: fadeInSiteNoScale 0.5s ease-in-out forwards;
}
.fade-in-content-no-scale {
    /* opacity: 0; */
    animation: fadeInSiteNoScale 0.5s ease-in-out forwards;
}
.loading-fade-out {
    /* opacity: 1; */
    animation: fadeOutContent 0.5s ease-in-out forwards;
}
.nav-wrapper, .mobile-nav {
    z-index: 1000;
}