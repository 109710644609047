.comments-section {
    width: 100%;
}
.comments-toggle {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    opacity: 0.5;
    cursor: pointer;
    user-select: none;
}
.no-comments {
    cursor: default;
    opacity: 0.3;
}
.comments-wrapper {
    overflow: hidden;
    max-height: 0;
}
.comments-wrapper.open {
    overflow: auto;
    max-height: 400px;
    transition: max-height 0.4s ease;
}
.comments-wrapper.closed {
    max-height: 0;
    transition: max-height 0.2s ease;
}
.comment-items-wrapper {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 400px;
    overflow-y: auto;
    /* border-top: 1px solid rgba(255,255,255,0.15);
    border-bottom: 1px solid rgba(255,255,255,0.15); */
    padding: 12px 12px;
    /* padding-right: 8px; */
    background: rgba(255,255,255,0.04);
    border-radius: var(--border-radius-2)
}
.comment-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.comment-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
}
.comment-info-wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.comment-user-and-time {
    display: flex;
    flex-direction: row;
    gap: 4px;
    font-size: 12px;
}
.comment-user {
    font-weight: 700;
    cursor: pointer;
}
.comment-timestamp {
    opacity: 0.5;
}
.comment-user-pic {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    cursor: pointer;
}
.comment-actions-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 32px;
}
.comment-upvote {
    /* margin-right: 8px; */
}
.comment-delete {
    padding: 4px;
    cursor: pointer;
    opacity: 0.3;
    transition: var(--transition-1);
    border-radius: var(--border-radius-2);
    display: flex;
    align-items: center;
}
.comment-delete:hover {
    opacity: 1;
    background: rgba(255,255,255,0.2);
}
.comment-delete:active {
    opacity: 0.5;
}

/* ----------------- NEW COMMENT ----------------- */
.new-comment-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    height: 44px;
    margin-top: 12px;
}
.textarea-new-comment {
    height: 100%;
}
.icon-comment-send-wrapper {
    padding: 0 6px;
    background-color: transparent;
    border-radius: var(--border-radius-2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    border-radius: 50%;
    height: 32px;
}
.icon-comment-send-wrapper, .icon-comment-send {
    transition: all 0.5s ease;
}
.icon-comment-send-wrapper .icon-comment-send {
    opacity: 0.3;
}
.icon-comment-send-wrapper.active {
    background-color: var(--color-blue-1);
}
.icon-comment-send-wrapper.active .icon-comment-send {
    opacity: 1;
}

/* ------------------- REPLIES ------------------- */
.comment-replies-wrapper {
    margin-left: 40px;
}
.new-reply-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 44px;
    margin-top: 8px;
    margin-bottom: 8px;
}
.comment-reply-button {
    font-size: 12px;
    font-weight: 600;
    opacity: 0.5;
    cursor: pointer;
    width: min-content;
}
.comment-reply-button.reply-cancel {
    opacity: 0.5;
}
.comment-view-replies-button {
    font-size: 12px;
    font-weight: 600;
    opacity: 0.5;
    cursor: pointer;
}

.show-hide-replies-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    margin-top: 4px;
    width: 100%;
    cursor: pointer;
    user-select: none;
}
.show-hide-line {
    height: 1px;
    background: rgba(255,255,255,0.1);
    width: 28px;
    flex: 1;
}

.textarea-new-reply {
    height: 44px;
}

.reply-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 4px;
}
.reply-actions-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 32px;
}
.replies-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 8px;
}
.reply-info-wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.reply-user-and-time {
    display: flex;
    flex-direction: row;
    gap: 4px;
    font-size: 12px;
}
.reply-user {
    font-weight: 700;
    cursor: pointer;
}
.reply-timestamp {
    opacity: 0.5;
}
.reply-user-pic {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    cursor: pointer;
}
.icon-reply-wrapper {
    height: 28px;
}

@media only screen and (max-width: 1000px) {
    .comment-items-wrapper {
        max-height: 280px;
    }
    .textarea-new-reply {
        font-size: 16px;
    }
    .textarea-new-comment {
        font-size: 16px;
    }
}