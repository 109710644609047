.mini-header {
    padding: 16px;
}
.mini-header-contents {
    padding-top: 2px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    opacity: 0.5;
    transition: var(--transition-1);
}
.div-center {
    width: 100%;
    height: calc(100% - 120px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 288px;
}
.new-group-subtext {
    margin-top: 8px;
    text-align: center;
    opacity: 0.8;
}

.new-group-dropzone {
    margin-top: 24px;
    border: 2px solid rgba(255,255,255,0.2);
    width: 200px;
    height: 200px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 0 40px 0 rgba(255,255,255,0.1);
    transition: var(--transition-1);
}
.new-group-dropzone:hover {
    border: 2px solid rgba(255,255,255,0.5);
    box-shadow: 0 0 40px 0 rgba(255,255,255,0.2);
}
.new-group-dropzone:hover .icon-plus-dropzone {
    opacity: 0.75;
}
.no-hover.new-group-dropzone:hover {
    border: 2px solid rgba(255,255,255,0.2);
    box-shadow: 0 0 40px 0 rgba(255,255,255,0.1);
}


.new-group-picture-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.new-group-picture-container {
    width: 200px;
    height: 200px;
    border-radius: 8px;
    overflow: hidden;
}
.dropzone {
    margin-top: 32px;
    width: 200px;
    height: 200px;
    border: 1px dashed rgba(255,255,255,0.5);
    border-radius: var(--border-radius-1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 0 40px 0 rgba(255,255,255,0.1);
    transition: var(--transition-1);
}
.icon-plus-dropzone {
    opacity: 0.4;
    transition: var(--transition-1);
}
.dropzone:hover {
    border: 1px dashed rgba(255,255,255,0.75);
    box-shadow: 0 0 40px 0 rgba(255,255,255,0.2);
}
.dropzone:hover .icon-plus-dropzone {
    opacity: 1;
    transform: scale(1.1);
}

.txt-btn-photo-action {
    margin-top: 16px;
    opacity: 0.5;
    transition: var(--transition-1);
    cursor: pointer;
    color: white;
}
.txt-btn-photo-action:hover {
    opacity: 1;
    color: var(--color-blue-1);
}

.input-group-name {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 24px;
}
.group-name-label {
    margin-top: 32px;
    color: white;
    font-weight: 600;
}

/* New styles for the slider */
.slider-container {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
}
.slider-label {
    color: white;
    font-size: 14px;
}

@media only screen and (max-width: 1000px) {
    .new-group-picture-container {
        width: 120px;
        height: 120px;
    }
    .new-group-dropzone {
        margin-top: 24px;
        width: 120px;
        height: 120px;
    }
    .div-center {
        height: calc(70vh - 0px);
    }
}