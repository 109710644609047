/* ------------------------ GENERAL ------------------------ */
.x-position-wrapper {
    position: absolute;
    left: -9999px;
}
.x-container {
    position: absolute;
}
.x-p5-wrapper {
    position: absolute;
    z-index: 1000;
}

.x-list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 18px;
}
.x-artist-details-wrapper {
    display: flex;
    flex-direction: row;
}

.x-stage-selection-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
}
.x-selectable-stage {
    padding: 4px 10px;
    border: 1px solid rgba(255,255,255,0.3);
    border-radius: 100px;
    font-size: 12px;
    cursor: pointer;
    transition: var(--transition-1);
    user-select: none;
}
.x-selectable-stage:hover {
    background: rgba(255,255,255,0.1);
}
.x-selectable-stage.x-stage-selected {
    background: var(--color-blue-1);
    color: white;
    border: 1px solid var(--color-blue-1);
}
.x-selectable-stage.x-stage-disabled {
    background: transparent;
    opacity: 0.4;
    cursor: default;
}

.x-hang-tight {
    margin-top: -4px;
    text-align: center;
}
.x-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 20px;
    height: 20px;
    animation: x-spin 1s linear infinite;
    margin: auto;
    opacity: 0.5;
  }
  
  @keyframes x-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

/* ------------------------ DECORATION ------------------------ */
.x-style-time-wrapper {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 1;
}
.x-style-time-wrapper-2 {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 3px;
    z-index: 1;
}
.x-fp-wrapper {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    bottom: 56px;
    z-index: 1;
    gap: 4px;
}
.x-fp-icon {
    margin-top: 2px;
    margin-right: 2px;
}
.x-fp-text {
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    color: white;
}
.x-st-left {
    flex: 1;
    height: 3px;
}
.x-st-logo {
    border-radius: 50%;
}
.x-st-right {
    flex: 1;
    height: 3px;
}

/* -------------------------- SCHEDULE -------------------------- */
.x-sched-seed-wrapper {
    display: flex;
    flex-direction: row;
}
.x-header-seed {
    font-weight: 800;
    color: var(--color-blue-1);
    letter-spacing: 1px;
}
.x-sched-header-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
}
.x-shadow {
    text-shadow: 0px 0px 7px rgba(0,0,0,0.6);
}
.x-header-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}
.x-header-contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}
.x-header-contact-default {
    /* opacity: 0.6; */
}
.x-header-contact-info {
    font-weight: 800;
    color: var(--color-blue-1);
}
.x-day {
    font-weight: 700;
}
.x-header-divider {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}
.x-ad-time-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.x-ad-text {
    font-size: 32px;
    opacity: 0.85;
}
.x-ad-end {
    opacity: 0.6;
}
.x-ad-name-stage-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
}
.x-ad-name-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}
.x-ad-heart-icon {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
}
.x-ad-name {
    font-weight: 400;
    opacity: 0.9;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.x-ad-stage {
    opacity: 0.65;
    text-align: end;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.x-must-see {
    font-weight: 800;
    opacity: 1;
}

/* ----------------- PREVIEW ----------------- */
.x-preview-wrapper {
    position: absolute;
}

/* TEST COMPONENT */
.tester-component {
    position: absolute;
    top: 0;
    left: 0;
}

/* ----------------- BLOCKS SCHEDULE ----------------- */
.x-stage-header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    letter-spacing: 2px;
    font-weight: 800;
}