.members-wrapper {
    padding: 16px;
}
.share-link-wrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    padding: 8px 8px 8px 16px;
    background: var(--color-bg-3);
    border-radius: var(--border-radius-2);
    width: fit-content;
    margin-top: 12px;
    margin-bottom: 24px;
    transition: var(--transition-1);
}
.btn-copy-link {
    width: fit-content;
    padding: 6px 14px 6px 12px;
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    transition: var(--transition-1);
}
.btn-copy-link:focus {
    outline: none;
}

.members-list-header {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    opacity: 0.9;
}
.members-list-subtext {
    margin-top: 4px;
    max-width: 520px;
}
.members-list {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    user-select: none;
    max-height: 480px;
    flex-wrap: wrap;
}
.member-item {
    width: 240px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0;
}
.member-details {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    transition: var(--transition-1);
    cursor: pointer;
}
.member-name {
    opacity: 0.8;
}
.member-details:hover {
    font-weight: 700;
    opacity: 1;
}
.member-details:hover .member-name {
    opacity: 1;
}
.member-picture {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}
.member-item {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}
.member-calendar-icon {
    padding: 4px;
    opacity: 0.5;
    transition: var(--transition-1);
    border: none;
    outline: none;
}
.member-calendar-icon:hover {
    opacity: 1;
}
.member-calendar-icon:active {
    opacity: 0.3;
}
.admin-badge {
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1px;
    opacity: 0.5;
    padding-top: 1px;
}
.members-danger-zone {
    margin-top: 32px;
    width: 120px;
}
.btn-delete-group {
    margin-top: 12px;
}

@media only screen and (max-width: 1000px) {
    .members-list {
        max-height: unset;
    }
    .share-link-wrapper {
        width: unset;
    }
}