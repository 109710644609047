/* -------------------- SCHEDULE -------------------- */
.block-schedule-wrapper {
    padding-left: 16px;
    padding-bottom: 16px;
    width: calc(100% - 32px);
    height: min-content;
    overflow: visible;
}
.set-time-schedule-wrapper {
    padding-bottom: 16px;
}

/* ------------------- BLOCK SCHEDULE ------------------- */
.block-schedule-sections-wrapper {
    display: flex;
    flex-direction: column;
}
.block-schedule-helper-wrapper {
    opacity: 0.75;
}
.block-schedule-all-sections {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 16px;
}
.block-schedule-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.block-schedule-section-header-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}
.block-schedule-section-header {
    font-size: 13px;
    line-height: 20px;
    opacity: 0.5;
    letter-spacing: 4px;
    text-transform: uppercase;
}
.block-schedule-section-header-tier {
    display: flex;
    align-items: center;
    gap: 8px;
}
.tier-number {
    opacity: 0.85;
    font-weight: 600;
}
.block-schedule {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 8px;
    transition: var(--transition-1);
}

/* ------------------ SET TIME SCHEDULE ------------------ */
.set-time-schedule {
    position: relative;
    display: flex;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 8px;
    z-index: 200;
    overflow-x: hidden;
    overflow-y: hidden;
}
.x-set-time-schedule {
    position: relative;
    display: flex;
    padding-left: 0;
    padding-right: 0;
    z-index: 200;
    overflow-x: hidden;
    height: min-content;
}

/* ----------------------- TIMELINE ----------------------- */
.timeline {
    position: relative;
    user-select: none;
    margin-bottom: 8px;
    padding-top: 40px;
    top: 4px;
    padding-left: 16px;
    border-right: 1px solid rgba(255,255,255,0.2);
    box-shadow: 3px 2px 5px 0 rgba(255,255,255,0.05);
}
.x-timeline {
    position: relative;
    user-select: none;
    margin-bottom: 12px;
    padding-top: 54px;
    padding-left: 12px;
    border-right: 1px solid rgba(255,255,255,0.2);
    background: none;
}
.time-slot {
    position: relative;
}
.time-label {
    position: absolute;
    top: 2px;
    right: 3px;
    letter-spacing: -1px;
    font-weight: 700;
}
.time-divider {
    position: absolute;
    z-index: 0;
    left: 33px;
    opacity: 0.2;
    overflow: hidden;
}
.x-time-divider {
    position: absolute;
    z-index: 0;
    left: 33px;
    opacity: 1;
    overflow: hidden;
}

/* ------------------------- STAGE ------------------------- */
.all-stages-wrapper {
    display: flex;
    overflow-x: auto;
}
.main-stages-wrapper {
    padding-left: 2px;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
    backface-visibility: hidden;
}
.x-main-stages-wrapper {
    padding-left: 2px;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
}
.stages-wrapper {
    display: flex;
    padding-left: 2px;
}
.stage-headers-wrapper {
    display: flex;
    padding-left: 2px;
    /* padding-right: 12px; */
    padding-bottom: 8px;
    z-index: 100;
    will-change: transform;
    background-color: transparent;
    width: fit-content;
    transition: background-color 0.3s ease,
                backdrop-filter 0.5s ease,
                -webkit-backdrop-filter 0.5s ease;
}
.single-stage-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
}
.stage-blocks {
    position: relative;
    margin: 0 5px 0 5px;
    will-change: transform;
    backface-visibility: hidden;
}

.stage-single-header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 16px;
    padding-bottom: 0;
}
.x-stage-single-header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 8px;
    padding-bottom: 4px;
}
.stage-header-text {
    position: relative;
    /* font-size: 10px; */
    line-height: 12px;
    font-weight: 500;
    text-align: center;
    color: white;
    z-index: 2;
    text-transform: uppercase;
    letter-spacing: 1px;
    max-height: 32px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-clamp: 1;
    box-orient: vertical;
    transition: var(--transition-1);
}
.x-stage-header-text {
    position: relative;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 8px;
    width: 100%;
    padding-bottom: 4px;
}
.stage-header-decoration {
    position: relative;
    margin-top: 8px;
    z-index: 1;
}

.stage-header-decoration .deco-1 {
    position: absolute;
    height: 0;
    z-index: 1;
}
.stage-header-decoration .deco-2 {
    position: absolute;
    height: 0;
    z-index: 1;
    animation: blink-shadow 2s ease-in-out infinite;
    animation-play-state: paused; /* We'll start the animation via JavaScript */
    animation-fill-mode: backwards; /* This will apply the initial keyframe state before the animation starts */
}
.stage-header-decoration .deco-3 {
    position: absolute;
    height: 1px;
    z-index: 1;
}

@keyframes blink-shadow {
    0%, 100% {
        box-shadow: 0 14px 36px 6px var(--shadow-color-dim);
    }
    50% {
        box-shadow: 0 14px 36px 6px var(--shadow-color-bright);
    }
}

.sticky-header-day {
    opacity: 1;
    transition: var(--transition-1);
    position: absolute;
    top: 14px;
    left: 16px;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
    z-index: 999;
    /* background: rgba(22, 25, 37, 0.9); */
    background: rgba(22, 25, 37, 0.9);
    padding: 4px 5px;
    border: 1px solid rgba(255,255,255,0.2);
    border-radius: var(--border-radius-2);
    transform: translateX(-4px);
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
    cursor: pointer;
    user-select: none;
}
.sticky-header-day:hover {
    border: 1px solid rgba(255,255,255,1);
}
.sticky-header-day:active {
    background: rgba(255,255,255,0.22);
}

@media only screen and (max-width: 1000px) {
    .sticky-header-day {
        top: 10px;
        left: 12px;
    }
    .stage-headers-wrapper.sticky-active {
        background-color: rgba(17, 17, 17, 0.85);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .set-time-schedule-wrapper {
        padding-bottom: 0;
    }
    .set-time-schedule {
        padding-bottom: 8px;
    }
    .timeline {
        margin-bottom: 8px;
    }
}