.avatar-set {
    display: flex;
    align-items: center;
    margin-left: 4px;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    transform: translateY(-3px);
    user-select: none;
}
.avatar-set.fade-in {
    opacity: 1;
    transform: translateY(0px);
}
.avatar-set-container.fade-in {
    opacity: 1;
    transform: translateY(0px);
    transition: all 0.5s ease-in-out;
    visibility: visible;
}
.avatar-set-container.fade-out {
    opacity: 0;
    transform: translateY(-3px);
    transition: all 0.5s ease-in-out;
    visibility: hidden;
}

.avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-left: -6px;
    border: 2px solid var(--color-bg-2);
    cursor: pointer;
    opacity: 1;
    transition: all 0.3s ease;
}
.avatar:hover {
    transform: scale(1.2) translateY(-2.4px);
    border: 2px solid rgba(255,255,255,0);
    z-index: 100 !important;
}

.extra {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 11px;
    letter-spacing: -1px;
    margin-left: -8px;
    border: 2px solid var(--color-bg-2);
    background-color: rgba(255,255,255,0.2);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    transition: var(--transition-1);
    opacity: 1;
}
.extra:hover {
    transform: scale(1);
    z-index: 0 !important;
    background-color: rgba(255,255,255,0.4);
}
.extra:active {
    transform: scale(1);
    z-index: 0 !important;
    background-color: rgba(255,255,255,0.2);
}

.avatar-block-single {
    position: relative;
}
.avatar-block {
    position: relative;
    border-radius: 50%;
    border: 1px solid var(--color-bg-2);
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
    cursor: default;
    opacity: 1;
    transition: all 0.3s ease;
}
.avatar-block:hover {
    transform: scale(1.2) translateY(-1.8px);
    z-index: 100 !important;
}
.avatar-block-heart {
    position: absolute;
    width: 10px;
    height: 10px;
    top: -10px;
    color: white;
    filter: drop-shadow(1px 1px 4px rgba(0,0,0,0.5));
    opacity: 0;
    transition: var(--transition-1);
    transform: translateY(12px) scale(0.5);
}
.avatar-block-heart.must-see {
    opacity: 1;
    transform: translateY(0px) scale(1);
}
.avatar-extra {
    height: 18px;
    border-radius: 4px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    letter-spacing: -1px;
    border: 1px solid var(--color-bg-2);
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
    background-color: rgba(0,0,0,0.6);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    transition: var(--transition-1);
    opacity: 1;
}
.avatar-extra-plus {
    font-size: 9px;
    margin-right: 1px;
}
.avatar-extra:hover {
    transform: scale(1) translateY(0px);
    z-index: 1 !important;
}