.festivals-wrapper {
    background-color: var(--color-bg-2);
    border-radius: var(--border-radius-1);
    padding: 18px 8px 16px 8px;
    margin: 12px 12px 12px 0;
    margin-left: calc(var(--nav-width) + 2 * (var(--page-margin)));
    box-sizing: border-box;
    width: calc(100% - 1 * (var(--page-margin)));
    height: calc(100vh - (2 * var(--page-margin)));
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background: linear-gradient(#141725 25%, #0C0E19);
}
.festivals-intro-text {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    gap: 4px;
}
.upcoming-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.upcoming-text {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.upcoming-subtext {
    opacity: 0.75;
}
.festivals-lmk {
    display: flex;
    flex-direction: row;
    gap: 4px;
    margin-top: -6px;
}
.upcoming-cards {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
}
.past-page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}
.past-cards {
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
}
.festival-month-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    /* padding-top: 24px; */
}
.festival-month-border {
    height: 1px;
    background-color: rgba(255,255,255,0.12);
    margin: 0 8px;
    margin-bottom: 16px;
}
.festival-month-header {
    margin-left: 8px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 15px;
    opacity: 0.6;
}
.festival-month-cards {
    margin-top: 4px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
}

/* ------------ FESTIVAL CARD ------------ */
.f-card-container {
    padding: 8px;
    width: 240px;
    background-color: transparent;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    cursor: pointer;
    transition: all 0.35s ease;
}
.f-card-container:hover {
    /* background-color: var(--color-bg-4); */
    border-radius: var(--border-radius-1);
    background-color: rgba(255,255,255,0.15);
    transform: scale(1.02);
    box-shadow: 0px 10px 10px 10px rgba(0, 0, 0, 0.2);
}
.f-card-details {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.f-card-name-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.f-card-name-row h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 24px);
}
.f-name {
    font-size: 18px;
    line-height: 24px;
    font-weight: 900;
}
.icon-favorite-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: var(--transition-1);
}
.icon-f-card-favorite {
    transition: all 0.3s ease-in-out;
    opacity: 0.5;
    padding: 4px;
    margin-right: -4px;
    margin-top: -2px;
}
.icon-f-card-favorite:hover {
    opacity: 1;
    transform: scale(1.2) translateY(-1.8px);
}
.icon-f-card-favorite.filled {
    fill: var(--color-festival-favorite);
    opacity: 1;
}

.f-tags {
    display: flex;
    flex-direction: row;
    gap: 6px;
}

.f-card-image-wrapper {
    position: relative;
    width: 240px;
    height: 300px;
}
.image-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: #f0f0f0; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-weight: 300;
    color: rgba(255,255,255,0.5);
}
.f-image {
    width: 240px;
    height: 300px;
    object-fit: contain;
    border-radius: 4px;
    box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.4);
    transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
    opacity: 0;
    transform: scale(0.99);
}
.f-image.loaded {
    opacity: 1;
    transform: scale(1);
            /* rotateX(0deg) 
            rotateY(40deg) 
            rotateZ(0deg); */
}

.icon-f-card-metadata {
    opacity: 0.5;
}
.f-card-metadata {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.f-metadata-row {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    opacity: 0.5;
}

/* ------------------------ MEDIA QUERIES ------------------------ */
@media only screen and (max-width: 1000px) {
    .festivals-wrapper {
        border-radius: 0;
        padding: 24px 12px 16px 12px;
        margin: 0;
        box-sizing: border-box;
        width: 100%;
        background: linear-gradient(#111220 25%, #090A11);
        overflow: unset;
        flex: 1;
        height: auto;
    }
    .upcoming-cards {
        padding-bottom: 24px;
    }
    .f-card-container {
        flex-direction: row;
        width: 100%;
    }
    .f-card-image-wrapper {
        height: 100px;
        width: 80px;
    }
    .f-image {
        height: 100px;
        width: 80px;
    }
    .f-card-details {
        width: 100%;
        gap: 6px;
    }
    .f-card-name-row h3 {
        font-size: 16px;
        line-height: 20px;
    }
    .f-card-metadata {
        margin-top: 4px;
    }
    .page-context-bar {
        display: none;
    }
    .icon-f-card-favorite {
        width: 24px;
        height: 24px;
    }
    .icon-f-card-favorite:hover {
        opacity: 1;
        transform: scale(1.1) translateY(-1.2px);
    }
    .festival-month-header {
        font-size: 14px;
    }
    .festival-month-cards {
        margin-top: 0;
    }
}