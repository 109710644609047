.four-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.four-subtext {
    margin-top: 8px;
    margin-bottom: 4px;
    opacity: 0.5;
}