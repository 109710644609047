/* ---------------------- NAV ---------------------- */
.nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(var(--nav-width) + (2 * (var(--page-margin))));
    padding: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-sizing: border-box;
}
.nav-top {
    background-color: var(--color-bg-2);
    border-radius: var(--border-radius-1);
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
}
.nav-bottom {
    background-color: var(--color-bg-2);
    border-radius: var(--border-radius-1);
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px;
    flex-grow: 1;
    overflow: auto;
}
.nav-single {
    background: linear-gradient(#141725 50%, #0C0E19);
    border-radius: var(--border-radius-1);
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
    flex-grow: 1;
    height: 100%;
    margin-bottom: 12px;
}
.nav-single-unauth {
    background: var(--color-bg-2);
    border-radius: var(--border-radius-1);
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
}

.nav-scroll-wrapper {
    overflow-y: auto;
    /* height: 800px; */
    flex-grow: 1;
}
.nav-festival-sections {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex-grow: 1;
}
.nav-section {
    display: flex;
    flex-direction: column;
}
.nav-section-last {
    padding-bottom: 60px;
}
.nav-section-header {
    padding: 0 16px;
    margin-bottom: 8px;
}
.nav-section-header-with-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 16px;
    margin-bottom: 8px;
}
.nav-section-header-action {
    font-size: 14px;
    line-height: 14px;
}
.nav-section-empty-text {
    font-size: 14px;
    line-height: 22px;
    opacity: 0.4;
    margin-left: 16px;
    margin-top: 2px;
    margin-right: 16px;
}

/* ---------------------- FAVORITES ---------------------- */
.nav-bottom-shortcuts {
    background-color: var(--color-bg-2);
    border-radius: var(--border-radius-1);
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px 12px;
    flex-grow: 1;
    overflow: auto;
}
.nav-shortcuts-header-text {
    padding: 0 4px;
}
.nav-favorites-rows {
    margin-top: 6px;
}
.nav-favorites-empty {
    margin-top: 6px;
    padding: 0 4px;
}
.nav-favorites-link {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    color: var(--color-text-primary);
}
.nav-favorite-content {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    width: 100%;
    transition: var(--transition-1);
    padding: 6px 0;
}
.nav-shortcut-icontext {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
    align-items: center;
    opacity: 0.6;
    transition: var(--transition-1);
}
.nav-shortcut-icontext:hover {
    opacity: 1;
}
.nav-festival-details-name {
    transition: var(--transition-1);
}
.nav-shortcut-icontext:hover .nav-festival-details-wrapper .nav-festival-details-name {
    font-weight: 700;
}
.nav-arrow-right {
    opacity: 0.3;
    transition: var(--transition-1);
}
.nav-shortcut-icontext:hover .nav-arrow-right {
    opacity: 1;
}
.nav-festival-details-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.nav-festival-details-date {
    font-size: 12px;
    line-height: 12px;
    opacity: 0.7;
}
.nav-icon-heart-filled {
    fill: var(--color-festival-favorite);
    padding: 4px;
    border-radius: var(--border-radius-2);
    transition: var(--transition-1);
}
.nav-icon-heart {
    padding: 4px;
    border-radius: var(--border-radius-2);
    transition: var(--transition-1);
}
.nav-icon-heart-filled:hover {
    background: var(--color-bg-3);
}
.nav-icon-heart:hover {
    background: var(--color-bg-3);
}
.nav-shortcuts-section {
    margin-top: 24px;
}

/* ------------------------ NAV AUTH ------------------------ */
.nav-signup-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.nav-signup-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}
.nav-signup-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

/* ---------------------- FESTIVAL INFO ---------------------- */
.nav-festival-info-wrapper {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 16px 8px;
    background: linear-gradient(90deg, 
        rgba(255, 164, 113, 0.15), 
        rgba(255, 82, 171, 0.15), 
        rgba(0, 163, 255, 0.15));
    border-bottom: 1px solid;
    border-image: linear-gradient(90deg, #FFA471, #FF52AB, #00A3FF) 1;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);
    width: calc(100% - 16px);
}
.nav-festival-info-image {
    width: 58px;
    height: 72px;
    box-shadow: 0px 2px 10px 10px rgba(0, 0, 0, 0.15);
    border-radius: var(--border-radius-2);
}
.nav-festival-info-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: calc(100% - 72px);
}
.nav-festival-info-header {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.nav-f-i-subtext-container {
    display: flex;
    flex-direction: row;
    gap: 6px;
}
.nav-f-i-subtext-item {
    font-size: 12px;
    line-height: 12px;
}


/* ----------------------- NAV TITLE ----------------------- */
.nav-logo-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 12px 16px 16px;
}
.nav-logo {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    user-select: none;
}
.nav-logo-circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: linear-gradient(135deg, #FFA471, #FFA471 14.64%, #FF52AB 50%, #00A3FF 85.36%, #00A3FF);
}
.nav-logo-text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 900;
    letter-spacing: -0.25px;
}
.logotext-container {
    font-size: 20px;
    line-height: 20px;
    font-weight: 900;
    color: var(--color-text-primary);
    user-select: none;
    cursor: pointer;
}
.nav-propic {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(255,255,255,0.3);
    object-fit: cover;
    cursor: pointer;
    transition: var(--transition-1);
}
.nav-propic:hover {
    border: 1px solid rgba(255,255,255,0.9);
}
.nav-propic:active {
    border: 1px solid rgba(255,255,255,0.7);
}
.nav-propic-img {
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

/* -------------------- NAV ROW -------------------- */
.nav-row {
    margin: 0 8px;
    padding: 8px 4px 8px 8px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    border-radius: var(--border-radius-2);
    transition: var(--transition-1);
    position: relative;
}
.nav-row-main {
    text-decoration: none;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    color: white;
}
.nav-row-active {
    opacity: 1;
    font-weight: 700;
    background-color: var(--color-bg-3);
}
.nav-row-recent {
    padding: 6px 4px 6px 8px;
}

.nav-row p, .nav-row .nav-row-icon {
    opacity: 0.5;
    transition: var(--transition-1);
}

.nav-row:hover p, .nav-row:hover .nav-row-icon {
    opacity: 0.8;
}

.nav-row-active p, .nav-row-active .nav-row-icon {
    opacity: 1;
    font-weight: 700;
}

.nav-row img.nav-row-picture {
    width: 22px;
    height: 22px;
    border-radius: 2px;
    /* margin-right: 4px; */
    opacity: 1 !important;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(255,255,255,0.3);
}
.nav-row img.nav-row-recent-picture {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    /* margin-right: 4px; */
    opacity: 1 !important;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(255,255,255,0.3);
}

.nav-row-remove-icon {
    cursor: pointer;
    opacity: 0.5;
    transition: var(--transition-1);
    color: white;
    padding: 4px;
    margin-right: 2px;
    border-radius: var(--border-radius-2);
}
.nav-row-remove-icon:hover {
    opacity: 1;
    background: var(--color-bg-3);
}
.nav-row-remove-icon:active {
    opacity: 0.5;
}
.nav-row-active .nav-row-remove-icon:hover {
    opacity: 1;
    background: var(--color-bg-2);
}
.nav-row-member-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    color: white;
    padding-right: 8px;
    opacity: 0.7;
}
.nav-row-member-count {
    font-size: 12px;
    font-weight: 400;
}

/* -------------------- INDICATOR -------------------- */
@keyframes blink {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    50% {
        opacity: 0;
        transform: scale(0.75);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
.nav-row-indicator {
    position: absolute;
    top: 16px;
    right: 10px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--color-blue-1);
    transition: var(--transition-1);
    animation: blink 3s infinite;
}

/* -------------------- NAV BACK -------------------- */
.nav-back-wrapper {
    margin-top: 8px;
}

/* --------------- NAV DISPLAY OPTIONS --------------- */
.display-options-wrapper {
    position: absolute;
    background-color: var(--color-bg-2);
    border-radius: var(--border-radius-1);
    border: 1px solid rgba(255,255,255,0.15);
    user-select: none;
    overflow: hidden;
    transition: var(--transition-1);
    bottom: 12px;
    left: 12px;
    width: var(--nav-width);
    box-shadow: 0px -4px 15px 1px rgba(0,0,0,0.2);
    width: calc(100% - 25px);
}
.display-options-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    padding: 12px 14px 12px 16px;
    margin-bottom: 8px;
}
.display-options-header-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}
.display-options-header-text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 800;
}
.display-options-header-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}
.nav-all-settings-wrapper {
    padding: 0 16px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.display-options-wrapper.collapsed {
    height: 52px;
    border: 1px solid var(--color-bg-2);
}
.display-options-wrapper.expanded {
    height: 340px;
}
.display-options-wrapper.expanded-2 {
    height: 310px;
}
.nav-setting-with-reset {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.nav-setting-wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}
.nav-toggle-wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    cursor: pointer;
}
.nav-toggle-margin {
    margin-top: 4px;
}
.nav-settings-label {
    font-size: 14px;
    line-height: 20px;
    opacity: 0.7;
    width: 88px;
}
.nav-settings-toggle-label {
    font-size: 14px;
    line-height: 20px;
    opacity: 0.7;
    transition: var(--transition-1);
}
.toggle-disabled {
    opacity: 0.3;
    cursor: not-allowed;
}
.nav-setting-section {
    margin-top: 16px;
    margin-bottom: 4px;
}
.nav-font-size-container {
    width: 20px;
    text-align: center;
}
.icon-nav-settings-stepper {
    cursor: pointer;
    padding: 4px;
    border-radius: var(--border-radius-2);
    transition: var(--transition-1);
}
.icon-nav-settings-stepper:hover {
    background: rgba(0,133,255,0.5);
}
.icon-nav-settings-stepper:active {
    background: rgba(0,133,255,0.1);
}
.icon-nav-settings-stepper.disabled {
    cursor: default;
    opacity: 0.3;
}
.icon-nav-settings-stepper.disabled:hover {
    background: transparent;
}
 .btn-nav-reset {
    width: max-content;
 }

 .icon-display-options-chevron {
    transition: var(--transition-1);
    transform: rotate(0deg);
    padding: 4px;
    border-radius: var(--border-radius-2);
 }
 .icon-display-options-chevron:hover {
    background: var(--color-bg-3);
 }
 .icon-display-options-chevron.rotated {
    transform: rotate(-180deg);
}
