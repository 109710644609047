.color-set-options-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 12px;
    padding: 0 6px;
}
.color-set-option {
    position: relative;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.color-set-icon {
    opacity: 0.5;
    transition: opacity 0.3s ease;
}
.color-set-option.selected {
    outline: 1px solid white;
    outline-offset: 4px;
}
.color-set-option.icon-selected {
    outline: 1px solid white;
    outline-offset: 4px;
    opacity: 1;
}

.color-set-option .color-half {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 0 100%);
    border-radius: 50%;
}

.color-set-option .color-half.right {
    clip-path: polygon(100% 0, 100% 100%, 0 100%);
}

.color-set-option.selected {
    outline: 1px solid white;
    outline-offset: 4px;
}

@media only screen and (max-width: 1000px) {
}