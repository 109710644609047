/* --------------------- GENERAL --------------------- */
.profile-context-bar {
    margin-left: 8px;
}
.profile-wrapper {
    --profile-container-width: 320px;
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* --------------------- PICTURE --------------------- */
.profile-picture {
    height: 104px;
    width: 104px;
    border-radius: 50%;
    border: 1px solid rgba(255,255,255,0.3);
}
.profile-dropzone {
    /* margin-top: 24px; */
    border: 2px solid rgba(255,255,255,0.2);
    width: 104px;
    height: 104px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 0 40px 0 rgba(255,255,255,0.1);
    transition: var(--transition-1);
}
.profile-circular-container {
    width: 104px;
    height: 104px;
    border-radius: 50%;
    overflow: hidden;
}

/* --------------------- BADGES --------------------- */
.profile-badges-wrapper {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}
.profile-badge {
    display: inline-block;
    position: relative;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
    font-weight: 400;
    padding: 4px 8px;
    border-radius: var(--border-radius-2);
    color: #fff;
    background: none;
}
.profile-badge::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    padding: 1px; /* Adjust the padding to control the border width */
    background: linear-gradient(135deg, #FFA471, #FF52AB, #00A3FF 90%);
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

/* --------------------- NAME --------------------- */
.profile-displayName {
    margin-top: 16px;
    font-size: 28px;
    line-height: 32px;
    font-weight: 900;
}

/* --------------------- INFO --------------------- */
.profile-info {
    width: var(--profile-container-width);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.profile-info-wrapper {
    width: var(--profile-container-width);
}
.profile-editing-actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}
.textarea-with-char-count {
    margin: 16px 0 24px 0;
}
.profile-bio-textarea {
    margin-bottom: 2px;
}
.textarea-char-count {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    opacity: 0.5;
}
.profile-edit-photo {
    width: var(--profile-container-width);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* --------------------- SOCIALS --------------------- */
.profile-social-inputs {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.profile-social-edit-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
}
.profile-social-editing-icon {
    opacity: 0.75;
    margin-right: 4px;
}
.input-social {
    padding: 6px;
    flex: 1;
}
.profile-social-icons-wrapper {
    width: 100%;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    gap: 4px;
    text-decoration: none;
    justify-content: center;
    align-items: center;
}
.profile-social-icon {
    padding: 4px;
    color: white;
    transition: var(--transition-1);
}
.profile-social-icon:hover {
    transform: scale(1.1);
    filter: drop-shadow(0px 0px 2px var(--hover-filter));
    color: var(--hover-color)
}
.icon-spotify:hover {
    --hover-color: #1DB954;
    --hover-filter: rgba(29, 185, 84, 0.4);
}
.icon-instagram:hover {
    --hover-color: #E4405F;
    --hover-filter: rgba(228, 64, 95, 0.4);
}
.icon-snapchat:hover {
    --hover-color: #FFFC00;
    --hover-filter: rgba(255, 252, 0, 0.4);
}
.icon-x:hover {
    --hover-color: #1DA1F2;
    --hover-filter: rgba(29, 161, 242, 0.4);
}
.profile-social-icon-link:active {
    opacity: 0.5;
}

/* ----------------- BIO + FAVORITES ----------------- */
.profile-section {
    width: var(--profile-container-width);
    text-align: center;
}
.send-a-msg-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
}
.profile-bio {
    margin-top: 24px;
}
.profile-favorites {
    margin-top: 32px;
}

@keyframes beat {
    0%, 20%, 50%, 80%, 100% {
        transform: scale(1);
    }
    40% {
        transform: scale(1.1);
    }
    60% {
        transform: scale(1.07);
    }
}
.profile-favorites-icon {
    padding: 1px;
    transition: var(--transition-1);
    color: var(--color-festival-favorite);
    animation: beat 1.2s infinite;
}
.profile-section-header {
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: bold;
}
.profile-section-title {
    padding: 0 10px;
    opacity: 0.75;
}
.profile-header-line-left {
    flex: 1;
    height: 1px;
    background: linear-gradient(to right, transparent, rgba(255,255,255,0.5));
    /* background: rgba(255,255,255,0.25); */
    box-shadow: 0px 2px 8px 0 rgba(0,0,0,0.3);
}
.profile-header-line-right {
    flex: 1;
    height: 1px;
    background: linear-gradient(to left, transparent, rgba(255,255,255,0.5));
    /* background: rgba(255,255,255,0.25); */
    box-shadow: 0px 2px 8px 0 rgba(0,0,0,0.3);
}
.profile-bio-text {
    margin-top: 12px;
    text-align: center;
}
.profile-favorites-text {
    margin-top: 4px;
    text-align: center;
}
.profile-favorited-list {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    max-height: 240px;
    overflow: auto;
}
.profile-favorited-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.icon-calendar-user {
    cursor: pointer;
    padding: 4px;
    transition: var(--transition-1);
}
.icon-calendar-user:hover {
    transform: scale(1.1);
    filter: drop-shadow(0px 0px 2px rgba(255,255,255,0.3));
}

/* --------------------- ACTIONS --------------------- */
.profile-actions {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
}

/* --------------------- LIGHTS --------------------- */
.lights-wrapper {
    position: relative;
    width: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
}
.single-light-wrapper {
    position: relative;
    width: 100%;
    margin-top: 8px;
    z-index: 1;
}
.light-1-top {
    position: absolute;
    top: 0;
    left: 124px;
    height: 1px;
    width: 32px;
    background: white;
    z-index: 1;
}
.light-1-bottom {
    position: absolute;
    top: 0;
    left: 124px;
    height: 0px;
    width: 32px;
    z-index: 0;
}
.light-1-glow {
    position: absolute;
    top: 0;
    left: 124px;
    height: 0px;
    width: 32px;
    z-index: 0;
}
.bottom-lights {
    position: relative;
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.light-2-top {
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 32px;
    background: white;
    z-index: 1;
}
.light-2-bottom {
    position: absolute;
    top: 0;
    left: 0;
    height: 0px;
    width: 32px;
    background: white;
    z-index: 0;
}
.light-2-glow {
    position: absolute;
    top: 0;
    left: 0;
    height: 0px;
    width: 32px;
    z-index: 0;
}
.light-3-top {
    position: absolute;
    top: 0;
    right: 0;
    height: 1px;
    width: 32px;
    background: white;
    z-index: 1;
}
.light-3-bottom {
    position: absolute;
    top: 0;
    right: 0;
    height: 0px;
    width: 32px;
    background: white;
    z-index: 0;
}
.light-3-glow {
    position: absolute;
    top: 0;
    right: 0;
    height: 0px;
    width: 32px;
    z-index: 0;
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes scaleIn {
    from {
        transform: scale(.995);
    }
    to {
        transform: scale(1);
    }
}
@keyframes dropIn {
    from {
        transform: translateY(-1.5px);
    }
    to {
        transform: translateY(0);
    }
}
.single-light-wrapper {
    opacity: 0.5;
    animation: fadeIn 0.36s ease-in-out forwards;
    animation-delay: 0s;
}
.bottom-lights {
    opacity: 0;
    animation: fadeIn 0.36s ease-in-out forwards;
    animation-delay: 0.05s;
}
.non-lights-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: fadeIn 0.75s ease-in-out forwards, scaleIn 0.5s ease-in-out forwards;
    animation-delay: 0.2s;
}



/* IGNORE */
.light-editing {
    position: absolute;
    top: -32px;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    transition: var(--transition-1);
    box-shadow: 0px 2px 5px 1px rgba(0,0,0,1);
}
.light-editing.light-1 {
    left: 132px;
}
.light-editing.light-2 {
    left: 12px;
}
.light-editing.light-3 {
    right: 12px;
}
.light-editing:hover {
    /* outline: 1px solid white;
    outline-offset: 4px; */
    transform: scale(1.2);
}

@media only screen and (max-width: 1000px) {
    .profile-wrapper {
        justify-content: flex-start;
        height: 100%;
        margin-top: 40px;
    }
    .non-lights-wrapper {
        padding-bottom: 40px;
    }
}