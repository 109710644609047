.modal-signup {
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: var(--transition-1);
    padding: 24px;
    overflow: auto;
}

/* ------------------------ HEADER ------------------------ */
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.modal-title-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}
.icon-modal-close {
    opacity: 0.5;
    transition: var(--transition-1);
    cursor: pointer;
}
.icon-modal-close:hover {
    opacity: 1;
}
.icon-modal-close:active {
    opacity: 0.75;
}

/* ------------------------ DROPZONE ------------------------ */
.signup-dropzone {
    margin-top: 24px;
    border: 2px solid rgba(255,255,255,0.2);
    width: 160px;
    height: 160px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 0 40px 0 rgba(255,255,255,0.1);
    transition: var(--transition-1);
}
.signup-dropzone:hover {
    border: 2px solid rgba(255,255,255,0.5);
    box-shadow: 0 0 40px 0 rgba(255,255,255,0.2);
}
.signup-dropzone:hover .icon-plus-dropzone {
    opacity: 0.75;
}
.no-hover.signup-dropzone:hover {
    border: 2px solid rgba(255,255,255,0.2);
    box-shadow: 0 0 40px 0 rgba(255,255,255,0.1);
}

.circular-container {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
}
.signup-slider-container {
    width: 144px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-top: 24px;
    user-select: none;
}
.signup-slider-label {
    color: white;
    font-size: 14px;
}

/* -------------------------- FORM -------------------------- */
.modal-signup-inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
    width: 100%;
}
.password-reset-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.modal-btn-signup-wrapper {
    margin-top: 24px;
    width: 100%;
}
.modal-signup-bottom {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-bottom: -4px;
}
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s ease infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 1000px) {
    .modal-signup {
        width: calc(100vw - 72px);
        height: 100%;
        padding: 16px;
    }
    .signup-dropzone {
        width: 100px;
        height: 100px;
    }
    .circular-container {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
    }
}