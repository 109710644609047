.announcements-page-wrapper {
    background-color: var(--color-bg-2);
    border-radius: var(--border-radius-1);
    padding: 18px 8px 16px 8px;
    margin: 12px 12px 12px 0;
    margin-left: calc(var(--nav-width) + 2 * (var(--page-margin)));
    box-sizing: border-box;
    width: calc(100% - 1 * (var(--page-margin)));
    height: calc(100vh - (2 * var(--page-margin)));
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background: linear-gradient(#141725 25%, #0C0E19);
}
.announcements-wrapper {
    --announcement-container-width: 720px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.announcements-page-header-wrapper {
    width: var(--announcement-container-width);
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
}
.announcements-page-title {
    margin-top: 16px;
    font-size: 28px;
    line-height: 32px;
    font-weight: 900;
    opacity: 0.9;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}
.announcements-subtext {
    margin-top: 8px;
    opacity: 0.8;
}

.announcements-list {
    display: flex;
    flex-direction: column;
    width: var(--announcement-container-width);
    padding: 0 24px;
    gap: 16px;
    margin-top: 12px;
}
.announcement-item {
    padding: 24px 0;
    border-bottom: 1px solid rgba(255,255,255,0.05);
}
.announcement-item-header-wrapper {
    display: flex;
    flex-direction: column;
}
.announcement-subject {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    width: fit-content;
    color: rgba(255,255,255,0.85);
}
.announcement-timestamp {
    font-size: 12px;
    opacity: 0.5;
}
.announcement-message-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;
}
.announcement-message-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
    opacity: 0.75;
}

/* -------------------- FOOTER -------------------- */
.announcement-item-footer-wrapper {
    width: var(--announcement-container-width);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
}
.announcement-tags {
    opacity: 0.3;
}
.announcement-created-by {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

/* HORIZONTAL */
.h-announcement-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 0;
    /* border-bottom: 1px solid rgba(255,255,255,0.12); */
    background: rgba(255,255,255,0.04);
    border-radius: var(--border-radius-1);
    padding: 16px;
    box-shadow: 2px 0px 20px 1px rgba(0,0,0,0.15);
}
.h-announcement-item.priority {
    border-radius: var(--border-radius-1);
    border: 2px solid transparent;
    background: linear-gradient(165deg, #25293b, #171b2a) padding-box,
                linear-gradient(135deg, #FFA471, #FF52AB 50%, #00A3FF) border-box,
                rgba(255, 255, 255, 0.04);
    background-clip: padding-box, border-box;
}
.h-announcement-user-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}
.h-announcement-user-details {
    display: flex;
    flex-direction: column;
}
.img-announcement-created-by {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}
.h-announcement-username {
    opacity: 0.9;
    font-size: 14px;
    transition: var(--transition-1);
}
.h-announcement-timestamp {
    font-size: 12px;
    line-height: 12px;
    opacity: 0.4;
    width: 120px;
}
.h-announcement-pinned-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    opacity: 0.5;
}
.h-announcement-item-content {
    flex: 1;
}
.h-announcement-item-footer-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    flex: 1;
}
.timestamp-date {
    opacity: 0.9;
    font-weight: 600;
}

/* -------------------- REAX / COMMENTS -------------------- */
.blasts-reax-comments-wrapper {
    margin-top: 16px;
    padding-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-top: 1px solid rgba(255,255,255,0.075);
}
.blasts-comments-wrapper {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    width: 100%;
}
.blasts-reax-wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

@media only screen and (max-width: 1200px) {
    .announcements-page-wrapper {
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
    }
    .announcements-wrapper {
        --announcement-container-width: 100%;
    }
    .page-context-bar {
        display: none;
    }
}

@media only screen and (max-width: 1000px) {
    .announcements-page-wrapper {
        margin: 0;
        padding: 0;
        margin-top: var(--m-nav-height);
        width: 100%;
        padding-bottom: 24px;
        background: linear-gradient(#111220 25%, #090A11);
        overflow-y: unset;
        margin-top: 0;
        border-radius: 0;
        flex: 1;
        height: auto;
    }
    .announcements-page-header-wrapper {
        padding-top: 8px;
        width: calc(100% - 32px);
    }
    .announcements-wrapper {
        --announcement-container-width: 100%;
        padding-bottom: 40px;
    }
    .announcements-list {
        padding: 0;
    }
    .h-announcement-item {
        flex-direction: column;
        gap: 16px;
        border-radius: 0;
    }
    .h-announcement-item.priority {
        border: none;
        border-top: 2px solid transparent;
        background: linear-gradient(165deg, #1d212f, #171b2a),
                    linear-gradient(90deg, #FFA471, #FF52AB 50%, #00A3FF) border-box top;
        background-clip: padding-box, border-box;
        border-radius: 0;
    }
    .h-announcement-timestamp.timestamp-date {
        width: max-content;
    }
    .h-announcement-timestamp-wrapper {
        align-items: flex-start;
        flex-direction: row;
        gap: 8px;
        align-items: center;
    }
    .festivals-wrapper {
        padding: 16px;
    }
}