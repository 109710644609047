.react-container {
    display: flex;
    flex-direction: row;
    gap: 6px;
    padding: 3px 6px;
    border: 1px solid rgba(255,255,255,0.25);
    border-radius: var(--border-radius-2);
    cursor: pointer;
    transition: var(--transition-1);
    user-select: none;
}
.react-container-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    padding: 3px 4px;
    border: 1px solid rgba(255,255,255,0.25);
    border-radius: var(--border-radius-2);
    cursor: pointer;
    transition: var(--transition-1);
}
.react-active {
    border: 1px solid rgba(255,255,255,0.8);
}
.react-text {
    font-size: 12px;
    line-height: 16px;
}
.react-emoji {
    opacity: 0.5;
    transition: var(--transition-1);
}
.react-emoji.active {
    opacity: 1;
}
.react-count-zero {
    opacity: 0.5;
}
.react-count {
    opacity: 0.9;
}