/* -------------------- MAIN HEADER -------------------- */
.main-header {
    padding: 18px 16px 16px 16px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
}
.mh-header-wrapper {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.mh-festival-name {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    opacity: 0.5;
    transition: var(--transition-1);
}
.separator-dot {
    height: 4px;
    width: 4px;
    background-color: white;
    border-radius: 50%;
}
.mh-explanatory-text {
    margin-top: 6px;
}

/* -------------------- MH TITLE -------------------- */
.mh-title-container {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}
.mh-heatmap-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    margin-left: -6px;
}
.mh-title-main {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}
.mh-title-user-info {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}
.mh-title {
    font-size: 28px;
    line-height: 32px;
    font-weight: 800;
    display: flex;
    flex-direction: row;
    align-self: center;
    gap: 4px;
}
.mh-title-picture {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin: 6px 0;
    margin-right: 4px;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
}
.img-loading-placeholder {
    border: 1px solid rgba(255,255,255,0.2);
    border-radius: 50%;
    width: 54px;
    height: 54px;
}
.mh-status-display {
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    color: var(--color-text-primary);
    transition: var(--transition-1);
    background-color: rgba(47, 235, 0, 0.15);
    padding: 4px 6px 4px 6px;
    border-radius: 2px;
    border: 1px solid rgb(47, 235, 0);
    margin-left: 6px;
    display: flex;
    align-items: center;
    user-select: none;
}
.status-view-only {
    border: 1px solid rgba(126,126,126,1);
    background-color: rgba(126,126,126,.15);
}
.mh-status-display-saving {
    color: rgb(255, 213, 0);
    background-color: rgba(255, 213, 0,0.1);
    border: 1px solid rgb(255, 213, 0);
}
.mh-status-dot {
    height: 6px;
    width: 6px;
    margin-right: 4px;
    border-radius: 100px;
    background-color: rgb(47, 235, 0);
    transition: var(--transition-1);
}
.mh-status-dot-saving {
    background-color: rgb(255, 213, 0);
}
.mh-last-update {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}
.mh-status-display-fixed {
    background-color: rgba(10, 48, 0, 0.85);
    position: fixed;
    bottom: 24px;
    right: 24px;
    z-index: 1000;
    box-shadow: 0 0 5px 1px rgba(0,0,0,0.25);
    opacity: 0;
    transform: translateY(24px) scale(0.98);
}
.mh-status-display-fixed.visible {
    opacity: 1;
    transform: translateY(0) scale(1);
}
.mh-status-display-saving-fixed {
    color: rgb(255, 213, 0);
    background-color: rgba(48, 40, 0, 0.85);
    border: 1px solid rgb(255, 213, 0);
}
.mh-title-share {
    margin-left: 4px;
}

/* -------------------- MH REACTS -------------------- */
.mh-reacts-container {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
}

/* -------------------- MH LEGEND -------------------- */
.mh-legend-container {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}
.mh-legend-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}
.mh-legend-text {
    opacity: 0.5;
}
.mh-legend-box-1 {
    height: 16px;
    width: 16px;
    background-color: rgba(0, 133, 255, 0.2);
    border: 1px solid rgba(0, 133, 255, 1);
    border-radius: var(--border-radius-2);
}
.mh-legend-box-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    background-color: rgba(0, 133, 255, 1);
    border: 1px solid rgba(0, 133, 255, 1);
    border-radius: var(--border-radius-2);
}

/* ----------------- FILTER OPTIONS ----------------- */
.schedule-options-wrapper {
    padding: 16px;
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    width: calc(100% - 32px);
    flex-wrap: wrap;
}
.schedule-options-wrapper-set-times {
    padding: 16px 16px 0 16px;
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    width: calc(100% - 32px);
    flex-wrap: wrap;
}
.schedule-options-clear {
    margin-left: 4px;
}

@media only screen and (max-width: 1000px) {
    .main-header {
        border-radius: 0;
        margin-top: 0;
        background: var(--color-bg-2);
    }
    .mh-festival-name {
        display: none;
    }
    .mh-title-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;
    }
    .mh-title {
        font-size: 22px;
        line-height: 28px;
    }
    .status-view-only {
        display: none;
    }
    .mh-title-picture {
        width: 36px;
        height: 36px;
    }
    .mh-title-share-icon {
        margin-left: 2px;
    }
    .mh-status-display-fixed {
        bottom: unset;
        top: 24px;
        transform: translateY(-24px) scale(0.98);
    }
    .mh-status-display-fixed.visible {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}