.mobile-nav-wrapper {
    width: 0;
    height: 0;
    visibility: hidden;
}
.overlay {
    visibility: hidden;
}
.mn-only {
    display: none;
    visibility: hidden;
}


@media only screen and (max-width: 1000px) {
    .mn-only {
        display: unset;
        visibility: visible;
    }
    .mobile-nav-wrapper {
        visibility: unset;
        position: fixed;
        opacity: 0;
        transform: translateX(100%);
        bottom: 12px;
        right: 0;
        padding: 0;
        width: min-content;
        max-width: calc(100% - 48px);
        background: rgba(10, 10, 10, 0.85);
        backdrop-filter: blur(0);
        -webkit-backdrop-filter: blur(0);
        z-index: 999;
        box-shadow: none;
        border: 1px solid rgba(255,255,255,0.2);
        border-radius: 8px;
        transition: all 0.3s ease;
        pointer-events: none;
        height: min-content;
        display: flex;
        flex-direction: column;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
        padding-right: 36px;
    }
    .show-m-nav {
        opacity: 1;
        transform: translateX(0px);
        height: min-content;
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        pointer-events: all;
        box-shadow: 0 2px 8px 0px rgba(255, 255, 255, 0.1);
    }
    .mn-contents {
        width: 100%;
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        overscroll-behavior-x: none;
    }
    .mn-contents-v {
        flex-direction: column;
    }
    .mn-icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        margin-left: 6px;
    }
    .mn-icons-wide-gap {
        gap: 8px;
        margin-right: 8px;
    }

    /* ------------------ EXPAND / COLLAPSE ------------------ */
    .mn-close-bar-wrapper {
        position: fixed;
        bottom: 20px;
        right: 0;
        display: flex;
        flex-direction: column;
        gap: -4px;
        opacity: 0.75;
        z-index: 999;
        border: 1px solid rgba(255,255,255,0);
        transition: all 0.2s ease;
        padding-top: 8px;
        padding-bottom: 2px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: -8px;
        border-left: 1px solid rgba(255,255,255,0.3);
        border-right: none;
    }
    .mn-close-bar {
        margin-top: -6px;
        transition: all 0.4s ease;
        color: var(--color-blue-1);
    }
    .mn-close-bar:nth-child(1) {
        transition-delay: 0s;
        color: #FFA471;
    }
    .mn-close-bar:nth-child(2) {
        transition-delay: 0.02s;
        color: #FF7B8E;
    }
    .mn-close-bar:nth-child(3) {
        transition-delay: 0.05s;
        color: #FF52AB;
    }
    .mn-close-bar:nth-child(4) {
        transition-delay: 0.09s;
        color: #BF66C0;
    }
    .mn-close-bar:nth-child(5) {
        transition-delay: 0.14s;
        color: #807BD5;
    }
    .mn-close-bar:nth-child(6) {
        transition-delay: 0.19s;
        color: #408FEA;
    }
    .mn-close-bar:nth-child(7) {
        transition-delay: 0.22s;
        color: #00A3FF;
    }
    .mn-close-bar-wrapper.mn-close-bar-show .mn-close-bar {
        transform: rotate(180deg);
        color: white;
    }
    .mn-close-bar-wrapper:not(.mn-close-bar-show) .mn-close-bar {
        transform: rotate(0deg);
    }
    .mn-close-bar-wrapper.mn-close-bar-show {
        padding-top: 8px;
        padding-bottom: 2px;
        /* padding-left: 6px; */
        margin-bottom: -8px;
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(4px);
        border: 1px solid rgba(255,255,255,0.5);
        border-right: none;
        opacity: 0.7;
        border-top-left-radius: var(--border-radius-2);
        border-bottom-left-radius: var(--border-radius-2);
    }
    .mn-festi-close-bar-wrapper {
        padding-top: 8px;
        padding-bottom: 4px;
        padding-left: 10px;
        padding-right: 8px;
        margin-bottom: -8px;
    }
    .mn-festi-close-bar-wrapper.mn-close-bar-show {
        padding-top: 8px;
        padding-bottom: 4px;
        margin-bottom: -8px;
    }
    
    /* ------------------------------------------------ */
    .mn-btn-signup {
        height: 36px;
        padding: 0 16px;
        width: 96px;
        min-width: fit-content;
        margin-right: 12px;
        margin-left: 4px;
    }
    .mn-propic {
        height: 24px;
        width: 24px;
        border-radius: 50%;
        border: 1px solid rgba(255,255,255,1);
        margin-right: 4px;
    }

    /* ------------------- EXPANDED CONTAINER ------------------- */
    .mn-expanded-container {
        visibility: hidden;
        position: fixed;
        bottom: 80px;
        opacity: 0;
        transform: translateY(20px);
        transition: var(--transition-1);
        background: rgba(10, 10, 10, 0.85);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        box-shadow: 0 0 10px 1px rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255,255,255,0.2);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        z-index: 999;
        width: calc(100% - 34px);
        padding: 12px;
        height: min-content;
        max-height: 45vh;
        overflow-y: auto;
        pointer-events: auto;
        right: auto;
    }
    .mn-expanded-sched-bar {
        bottom: 114px;
    }
    .mn-expanded-display-options {
        width: 240px;
    }
    .mn-expanded-recents {
        width: 200px;
        padding: 6px;
    }
    .mn-expanded-groups {
        width: 200px;
        padding: 6px;
    }

    /* ---------------------- FAVORITES ---------------------- */
    .mn-fav-visible {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
        transition: var(--transition-1);
    }
    .mn-favs-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 8px;
    }
    .mn-fav-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .mn-fav-details {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }
    .mn-fav-img {
        height: 36px;
        border-radius: 2px;
    }
    .mn-fav-text-wrapper {
        display: flex;
        flex-direction: column;
    }
    .mn-fav-name {
        font-weight: 600;
    }
    .mn-fav-dates {
        opacity: 0.5;
    }
    .mn-fav-icon {
        color: var(--color-festival-favorite);
    }

    /* ------------------------ GROUPS ------------------------ */
    .mn-groups-wrapper {
        display: flex;
        flex-direction: column;
    }
    .mn-group-num-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        opacity: 0.75;
    }
    .mn-group-img {
        width: 22px;
        height: 22px;
        border-radius: 2px;
    }

    /* ------------------------ RECENTS ------------------------ */
    .mn-recents-wrapper {
        display: flex;
        flex-direction: column-reverse;
    }
    .mn-recents-empty {
        padding: 6px;
        padding-top: 0;
    }
    .mn-recents-header {
        margin: 6px;
    }
    .mn-recent-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: white;
        text-decoration: none;
        padding: 6px;
        border-radius: var(--border-radius-2);
    }
    .mn-recent-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
    }
    .mn-new-group {
        color: var(--color-blue-1);
    }
    .mn-recent-item-active {
        background: var(--color-blue-1);
    }
    .mn-recent-item-active .mn-new-group {
        color: white;
    }
    .mn-recent-img {
        width: 22px;
        height: 22px;
        border-radius: 50%;
    }
    .mn-recent-remove {
        opacity: 0.5;
    }
    .mn-recent-remove:active {
        opacity: 1;
    }

    .mn-sched-visible {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
        transition: var(--transition-1);
    }
    .mn-empty-text {
        margin-top: 4px;
        opacity: 0.5;
    }

    /* ---------------------- FESTIVAL INFO ---------------------- */
    .mn-festival-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: calc(100% - 20px);
        padding: 0 10px 6px 10px;
    }
    .mn-festival-info-name {
        font-weight: 700;
        margin: 0 10px;
        opacity: 0.9;
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 2px;
    }
    .mn-festival-info-date {
        opacity: 0.5;
    }
    .mn-line {
        flex: 1;
        height: 1px;
    }
    .mn-line-1 {
        background: linear-gradient(90deg, #FFA471 0%, #FF52AB 50%, #00A3FF 100%);
    }
    .mn-line-2 {
        background: linear-gradient(-90deg, #FFA471 0%, #FF52AB 50%, #00A3FF 100%);
    }

    /* ------------------------ GENERAL ------------------------ */
    .display-options-header {
        padding: 0;
    }
    .nav-all-settings-wrapper {
        padding: 0;
    }

    .nav-wrapper {
        display: none;
    }
}