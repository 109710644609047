.tabs-container {
    position: relative;
    width: 100%;
    --tab-width: 96px;
    margin-top: 6px;
}

.tabs {
    display: flex;
}

.tab {
    padding: 8px 0;
    width: var(--tab-width);
    cursor: pointer;
    position: relative;
    transition: var(--transition-1);
    text-align: center;
    opacity: 0.5;
}
.tab:hover {
    opacity: 0.3;
}
.tab:active {
    opacity: 0.1;
}

.tab.active {
    font-weight: 700;
    opacity: 1;
}

.active-underline {
    position: absolute;
    height: 1px;
    background-color: rgba(255,255,255,1);
    width: var(--tab-width);
    transition: left 0.3s ease;
}
