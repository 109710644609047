@media only screen and (max-width: 1000px) {
    /* ------------------- MAIN ------------------- */
    .mn-icon {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: white;
        padding: 8px 12px 4px 12px;
        margin: 6px 0;
        border-radius: var(--border-radius-2);
        transition: var(--transition-1);
        opacity: 0.6;
        font-weight: 600;
        user-select: none;
    }
    .mn-icon-w-indicator {
        opacity: 1;
    }
    .mn-icon-label {
        font-size: 12px;
    }

    /* ------------------- STATES ------------------- */
    .mn-icon-active {
        background-color: var(--color-blue-1);
        opacity: 1;
    }
    .mn-icon-pressed {
        background-color: rgba(0,133,255,0.5);
        opacity: 1;
    }
    .mn-icon-red {
        color: var(--color-blue-1);
        margin-right: 4px;
    }
    .mn-icon-heatmap {
        padding-left: 8px;
        padding-right: 8px;
    }

    /* ------------------ INDICATOR ------------------ */
    .mn-icon-below-indicator {
        opacity: 0.6;
    }
    .mn-icon-w-indicator-text {
        opacity: 0.6;
    }
    .mn-icon-pressed .mn-icon-below-indicator {
        opacity: 1;
    }
    .mn-icon-pressed .mn-icon-w-indicator-text {
        opacity: 1;
    }
    .mn-icon-indicator {
        position: absolute;
        top: 10px;
        height: 10px;
        transition: var(--transition-1);
        transform: translateY(-10px);
        color: var(--color-blue-1);
    }
    .mn-icon-indicator-open {
        transform: translateY(-24px) scale(2);
        opacity: 0;
    }

    @keyframes blink {
        0% {
            opacity: 1;
            transform: scale(1);
        }
        50% {
            opacity: 0;
            transform: scale(0.75);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
    .mn-blasts-indicator {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: var(--color-blue-1);
        transition: var(--transition-1);
        animation: blink 2.5s infinite;
        z-index: 1000;
    }
}