.bottom-container-wrapper {
    position: fixed;
    bottom: 20px;
    width: calc(100% - var(--nav-width) - 36px);
    display: flex;
    justify-content: center;
    pointer-events: none;
    transition: width 0.15s ease-in-out;
}
.bottom-container {
    position: relative;
    border-radius: var(--border-radius-1);
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    background: rgba(22, 25, 37, 0.85);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border: 1px solid rgba(255,255,255,0.2);
    box-shadow: 0px 4px 10px 1px rgba(0, 0,05, 1);
    overflow-x: auto;
    pointer-events: all;
    transition: opacity 0.3s ease-in-out, width 0.3s ease-in-out;
    width: fit-content;
    opacity: 0;
}

.bottom-container-wrapper.fade-in .bottom-container {
    opacity: 1;
}

.bottom-container-wrapper.fade-out .bottom-container {
    opacity: 0;
}

/* .close-btn {
    align-self: flex-end;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
} */

.bottom-artist-name {
    font-size: 12px;
    font-weight: 800;
    margin-bottom: 6px;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 0 20px;
}
.bottom-vote-counts {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
}
.bottom-vote-counts-container {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}
.bottom-vote-separator {
    opacity: 0.5;
}
.bottom-vote-count-label {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    opacity: 0.8;
    font-size: 14px;
}
.bottom-vote-count-result {
    font-weight: 900;
    font-size: 14px;
}
.bottom-vote-heart {
    color: var(--color-festival-favorite);
}
.bottom-members {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.bottom-member {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    transition: var(--transition-1);
}
.bottom-member:hover {
    transform: scale(1.2) translateY(-3.2px);
}
.bottom-member-picture {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}
.vote-2 {
    /* border: 1.5px solid white; */
}
.bottom-heart {
    color: var(--color-festival-favorite);
    width: 14px;
    margin-left: -14px;
    margin-bottom: -24px;
    filter: drop-shadow(-2px -1.5px 3px rgba(0,0,0,0.6));
}
.bottom-close {
    position: absolute;
    top: 8.5px;
    right: 8.5px;
    background: none;
    cursor: pointer;
    color: white;
    padding: 3px;
    transition: var(--transition-1);
    border-radius: var(--border-radius-2);
}
.bottom-close:hover {
    opacity: 1;
    color: white;
    background: rgba(255,255,255,0.3);
}

@media only screen and (max-width: 1000px) {
    .bottom-container-wrapper {
        width: 100%;
    }
    .bottom-container {
        margin: 0 12px;
        width: 100%;
    }
    .bottom-members {
        width: 100%;
        padding-bottom: 1px;
        overflow-x: auto;
        overflow-y: hidden;
    }
}